import React, {useEffect, useState} from 'react';
import Header from './Header/Header';
import Footer from './Home/Footer';
 import './Search.css';

import { useNavigate } from 'react-router-dom';

const Search = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState();



  const SearchYahooPage = (event) => {
      
        //  alert(event);
      if(event.key === 'Enter') {
       // console.log("enter works");
       let searchYahoo = document.getElementById('searchYahooPage');
       // console.log(searchYahoo.value);
        let SYvalue = searchYahoo.value;
         
       
        navigate('/search?q='+ SYvalue);
        // console.log(queryString);
        const url = `/search?q=${SYvalue}`;
         window.history.pushState(null, null, url);
         let passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://taskticks.com&kw='+ SYvalue;
       // console.log(passLink);
        setTimeout(() => {
         //  navigate(passLink, { replace: true });
          window.open(passLink, '_target', 'noreferrer');
        }, 500)
        
        
      }
      
    }
    const enteredSearch = (e) => {
      setSearchValue(e.target.value);
      console.log(e.target.value);
    }

    useEffect(()=> {
     
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const qValue = urlParams.get('q');
      setSearchValue(qValue);
    }, [])
  return (
    <div>
    <Header />
    <div className='container pt-5'>
    
    
    <div className='row'>
    <div className='col-md-12 heightAd'>
     
     <div className='searchSection'>
      <div className='searchNameLogo'>
        <img src='./logo_todoist.png'></img> <small>TaskTicks</small>
       
      </div>
      <div className='searchInputData'>
       <input onKeyDown={SearchYahooPage} onChange={enteredSearch} type='text' placeholder='Search the web' id='searchYahooPage' value={searchValue} className='searchpageInput'></input>
       </div>
     </div>
     
     </div>
     
    </div>
    </div>
      <Footer/>
    </div>
  )
}

export default Search
