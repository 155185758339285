import React from "react";
import SideButton from "./SideButton";
import Header from "./Header";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../auth/config";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, Checkbox } from "@mui/material";
import AuthContext from "../../auth/Auth-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, blueGrey } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 270;
const theme = createTheme({
  palette: {
    primary: lime,
    secondary: blueGrey,
  },
});

function InviteUsers(props) {
  const history = useNavigate();  
  const inviteUserCollection = collection(db, "inviteUsers");
  const [permissionValue, setPermissionValue] = useState();
  const [permissionID, setPermissionID] = useState();
  const [sitePermission, setSitePermission] = useState([]);
  const [onloadData, setOnloadData] = useState([]);
  const [emailid, setEmailId] = useState();
  const [method, setMethod] = useState(false);
  const [updateID, setupdateID] = useState();
  // const [checked, setIsChecked] = useState(false);
  let selectedTabs = [];

  const authCtx = useContext(AuthContext);
  console.log(authCtx);
  console.log(sitePermission);
  useEffect(() => {
    // if(authCtx){
    //   if(authCtx.email != 'bellaryharsha4u@gmail.com'){
    //     alert("Access Denied");
    //    return false;
    //   } else {

    //   }
    // }
    authCtx.email = authCtx.email ? authCtx.email : null;
    console.log(authCtx.email);

    // getData();
    getOnloadList();
  }, []);

  // You can perform further actions based on the selected rows

  const permissionLevel = (event) => {
    //  alert(value)
    console.log(event.target.id, event.target.value);
    setPermissionValue(event.target.value);
    setPermissionID(event.target.id);
  };
  const getOnloadList = async () => {
    const data = await getDocs(inviteUserCollection);
    console.log(data.docs);
    let dataArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    console.log(dataArray);
    setOnloadData(dataArray);
  };
  const getUserinvite = async (event) => {
    event.preventDefault();
    let email_id = document.getElementById("userID").value;

    if (permissionValue && email_id) {
      let ifAlreadyExist = onloadData.find((item) => item.EmailId === email_id);
      if (ifAlreadyExist) {
        alert("already user Exist");
        return false;
      }
      console.log(permissionValue);
      await addDoc(inviteUserCollection, {
        EmailId: email_id,
        Level: permissionValue,
        SelectedID: permissionID,
        SitePermission: sitePermission,
      });

      alert("Access Granted Successfully");
    } else {
      if (!permissionValue) {
        alert("Please select the permission Level");
      } else {
        alert("Please add the valid Email id");
      }

      return false;
    }
  };

  const permissionLevelSiteControlHandlder = (event) => {
    var value;
    // selectedTabs = [value, ...selectedTabs];

    // console.log(selectedTabs);
    if (event.target.checked) {
      value = event.target.value;
      setSitePermission((prevSelectedTabs) => [value, ...prevSelectedTabs]);
    } else {
      value = event.target.value;
      setSitePermission((prevSelectedTabs) =>
        prevSelectedTabs.filter((item) => item !== value)
      );
      // const filteredArray = originalArray.filter(item => item !== valueToRemove);
      // console.log(checkedItems)
    }
  };

  const getuserID = (event) => {
    let value = event.target.value;
    setEmailId(value);
    console.log(event.target.value);
    if (value.includes("@")) {
      const ifAlreadyExist = onloadData.find((item) => {
        // Check if the EmailId property of the current item contains the specified substring
        return item.EmailId.includes(value);
      });
      console.log(ifAlreadyExist);
      if (ifAlreadyExist) {
        if (ifAlreadyExist.SitePermission) {
          ifAlreadyExist.SitePermission.map((item, index) => {
            console.log(item);
            document.getElementById(item).checked = true;
          });
          setSitePermission(ifAlreadyExist.SitePermission);
          document.getElementById(ifAlreadyExist.SelectedID).checked = true;
          setEmailId(ifAlreadyExist.EmailId);
          document.getElementById("userID").disabled = true;
          setPermissionValue(ifAlreadyExist.Level);
          setPermissionID(ifAlreadyExist.SelectedID);
          setMethod(true);
          setupdateID(ifAlreadyExist.id);
        }
      }
    } else {
      var btnCheck = document.querySelectorAll(".btn-check");
      if (btnCheck.length > 0) {
        // console.log(btnCheck);
        for (var i = 0; i < btnCheck.length; i++) {
          btnCheck[i].checked = false;
        }
        // btnCheck.map((item)=> {
        //        console.log(item);
        // })
      }

      // document.querySelector('.btn-check').checked = false;
    }
  };
  const updateUserinvite = async (e) => {
    const userDoc = doc(inviteUserCollection, updateID);
    console.log(userDoc);
    await updateDoc(userDoc, {
      EmailId: emailid,
      Level: permissionValue,
      SelectedID: permissionID,
      SitePermission: sitePermission,
    });
    alert("Updated Successfully");
  };
  const clearUser = (e) => {
    setEmailId("");
    document.getElementById("userID").disabled = false;
    setPermissionValue("");
    setPermissionID("");
    setMethod(false);
    var btnCheck = document.querySelectorAll(".btn-check");
    if (btnCheck.length > 0) {
      console.log(btnCheck);
      for (var i = 0; i < btnCheck.length; i++) {
        btnCheck[i].checked = false;
      }
      // btnCheck.map((item)=> {
      //        console.log(item);
      // })
    }
  };
  console.log(sitePermission);
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar style={{ background: "#fff" }}>
            <Typography
              style={{ color: "#333", fontWeight: "bold" }}
              variant="h6"
              noWrap
              component="div"
            >
              Analytics Tool
            </Typography>
            <Header />
          </Toolbar>
        </AppBar>
        <SideButton />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <div className="container pt-1">
            <h4>
              <b>Invite Users </b>
            </h4>

            <input
              className="form-control"
              onChange={getuserID}
              id="userID"
              value={emailid}
              type="email"
              placeholder="Email id"
            ></input>
            <div className="PermsionLevel mt-3">
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                Permission Level
              </div>
              <div
                className="btn-group mt-2"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  onClick={permissionLevel}
                  value="Restricted Access"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="btnradio1">
                  Restricted Access
                </label>

                <input
                  type="radio"
                  onClick={permissionLevel}
                  value="Full Access"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="btnradio2">
                  Full Access
                </label>
              </div>
            </div>
            <div className="PermsionLevel mt-3">
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                Give permission for below mentioned site's{" "}
              </div>
              <div
                className="mt-2"
                role="group"
                aria-label="Basic checkbox toggle button group"
              >
                {[
                  "Searcholl",
                  "yrr_mynewtab",
                  "yrr_wundrfly",
                  "Mynewtab",
                  "wundrfly",
                  "ollreview",
                  "vayunewtab",
                  "searchhello",
                  "safesearchengine",
                  "Lukall",
                  "speedtestgizmo",
                  "safesearchengine_net",
                  "safesearchengine_org",
                  "taskticks",
                ].map((text, index) => (
                  <div
                    className="inlineFlex"
                    style={{
                      display: "inline-block",
                      margin: "10px 10px 0px 0px",
                    }}
                  >
                    <input
                      type="checkbox"
                      onClick={permissionLevelSiteControlHandlder}
                      value={text}
                      className="btn-check"
                      name={text}
                      id={text}
                      autocomplete="off"
                    />
                    <label className="btn btn-outline-primary" for={text}>
                      {text}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* <label><input type='checkbox' value='Restricted Access'  checked/> Restricted Access</label>
            <label><input type='checkbox' value='Full Access' /> Full Access</label> */}

            <div>
              {method ? (
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={updateUserinvite}
                    variant="contained"
                    className=" mt-4"
                    type="submit"
                  >
                    Update Users
                  </Button>{" "}
                  <Button
                    onClick={clearUser}
                    variant="contained"
                    color="secondary"
                    className=" mt-4"
                    type="submit"
                  >
                    Clear Users
                  </Button>
                </ThemeProvider>
              ) : (
                <Button
                  onClick={getUserinvite}
                  variant="contained"
                  color="success"
                  className=" mt-4"
                  type="submit"
                >
                  Add Users
                </Button>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default InviteUsers;
