import React from 'react';
import SideButton from './SideButton';
import Header from './Header'
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import Yrr_wundrfly_data from './Yrr_wundrfly_data';


function Yrr_wundrfly(props) {
  const authCtx = useContext(AuthContext);
 
  // console.log(filterTerms)
  return (
      <div>    
        <Header/>
      <SideButton />
      {authCtx.email ? <Yrr_wundrfly_data/> : ''}   
          
      </div>
  );
}



export default Yrr_wundrfly;