import React, {useEffect, useState} from 'react';
import Header from '../Header/Header';
import Footer from '../Home/Footer';
import '../subpages/subpages.css';
import IconButton from '@mui/material/IconButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import axios from 'axios';


const HelpCenter = () => {
  const [HelpQuery, setHelpQuery] = useState();
  const [retrieveData, setRetrieveData] = useState([])
  const [boolean, setBoolen] = useState(false);
  const [value, setvalue] = useState(null)
  
  const onchangeHandler =(e)=> {
    setvalue(e.target.value);
    console.log(e.target.value)
    if(e.target.value === ''){
      getSearchDataByOnChangeHander('Help');
  }
}
 

  const getSearchDataByOnChangeHander = async(e) => {
    let query = '';
  
    setBoolen(false);
    console.log(e);
   
    if(e === 'Help'){
      // setHelpQuery(e)
      query = e;
      const SearchData = await axios.get(
        "https://wundrfly.com/blog/wp-json/wp/v2/posts?search="+query
      );
    
      console.log(SearchData)
      setRetrieveData(SearchData.data);
      setBoolen(true);
    } else {
      console.log(e);
       if (e.key === 'Enter') {
        query = e.target.value;
        const SearchData = await axios.get(
          "https://wundrfly.com/blog/wp-json/wp/v2/posts?search="+query
        );
      
        console.log(SearchData)
        if(SearchData.status === 200){
          setBoolen(true);
          setRetrieveData(SearchData.data);
        }
        
      }
      

        
     
    }
   
    
    //let SearchQuery = document.getElementById('searchQuery').value;
   
  }

  useEffect(()=> {
    getSearchDataByOnChangeHander('Help');
  }, [])


  console.log(retrieveData, retrieveData.length)
  return (
    <div>
      <Header/>
      <div className='abtContainer'>
         <div className='helpSectionInput'>
          <h1 style={{textAlign: 'center'}}>How can we help?</h1>
            <div className='searchBox'>
            <SearchSharpIcon className='searchIconLeft'/><input id='searchQuery' onChange={onchangeHandler}   type='search' placeholder='Search' onKeyDown={getSearchDataByOnChangeHander}/><span></span>
            </div> 
            {
              <ul>
                {
                  boolean ?   retrieveData.length > 0 ? retrieveData.map((item, index)=> {
                    console.log(item.title.rendered)
                   return (
                     <>
                     <a href={item.link} target='_blank' style={{textDecoration: 'none'}}>
                     <li key={index}>{item.title.rendered}</li>
                     </a>
                     </>
                   )
                    
                 }) : <div className='NotFound'>
                               <p>Loading Suggestions...</p>
                               <div className='loaderImage'>
                                <img src='./loader.gif' />
                               </div>
                       </div> : 
                  <div className='NotFound'>
                  <p>Loading Suggestions...</p>
                  <div className='loaderImage'>
                                <img src='./images/loader.gif' />
                               </div>
          </div>
                }
              
              
               
            </ul> 
            }
         </div>
         
        </div>
      <Footer/>
    </div>
  )
}

export default HelpCenter
