import React from 'react'
import './HomeBody.css';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';

const HomeBody = () => {

  const [view, setView] =  useState();




const viewChange = (value) => {
  setView(value)
  // alert(value)
}








  return (
    <div>
    <div className='HomeBodyH1'>
      <div className='firstDiv' style={{maxWidth: '1300px', margin: 'auto'}}>
      <div className='textCenter' style={{textAlign: "center"}}>
      
      <h1>Welcome to <span className='GradText'>TaskTicks</span></h1>
      <h3>Your all-in-one task management tool and to do list app</h3>
      <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link>
      </div>
       <div style={{textAlign: 'center'}}>
       <img src='./images/Home/Group 2117.png' className='imageAdjustMent' />
       </div>
        
       <div className='patnersListSection' >
       {/* <p>30 million+ people and teams trust their sanity and productivity to TaskTicks</p> */}
       </div>
       {/* <div className='partnersListImage'>
        <div className='logos'>
          <img src='./images/microsoft.png'></img>
          <img src='./images/Salesforce.png'></img>
          <img src='./images/Adobe.png'></img>
          <img src='./images/Neftlix.png'></img>
          <img src='./images/Amazon.png'></img>
          <img src='./images/Disney.png'></img>
        </div>
       </div> */}
        </div>
       </div>

       <div className='SecondBody'>
            <img src='../images/Home/Group 2118.png' style={{width: '100%'}}></img>
            <div className='buttonsMode text-center mt-5'>
          <Button onClick={() => viewChange('Desktop')} className='modeButton' sx={view === 'Desktop' ? {background: '#C49ADF!important', color: '#333'} : {background: '#ffffff!important', color: '#333'}}>Desktop</Button>
          <Button onClick={() => viewChange('Tablet')} className='modeButton' >Tablet</Button>
          <Button onClick={() => viewChange('Mobile')} className='modeButton' >Mobile</Button>
        </div>
        </div>

        
       <div className='infoslideShow'>
        <div className='info1'>
          <div className='leftSide' style={{marginLeft: '10rem'}}>
            {/* <b>Clear your mind</b> */}
            <h3>Achieve Harmony in Work and Life</h3>
            <div className='line'></div>
            <p>Finally, a seamless way to organize both your professional and personal world. Discover focus, order, and peace of mind with TaskTicks - the ultimate task manager and to-do list app</p>
            <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link></div>
     
          </div>
          <div className='rightSide' style={{textAlign: 'right'}}>
             <img src='../images/Home/Group 2114.png' style={{width: '75%'}}></img>
          </div>
        </div>
        <div className='info1'>
        <div className='rightSide' style={{textAlign: 'left'}}>
             <img src='../images/Home/Group 2113.png' style={{width: '75%'}}></img>
          </div>
          <div className='leftSide' style={{marginRight: '10rem'}}>
            {/* <b>Focus on what’s important</b> */}
            <h3>Effortlessly Clear Your Mind</h3>
            <div className='line'></div>
            <p>Say goodbye to mental overload. With TaskTicks, quickly unload tasks from your mind into a streamlined, intuitively organized to-do list, thanks to our advanced natural language recognition.</p>
            <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link></div>
     
          </div>
          
        </div>
        <div className='info1'>
          <div className='leftSide' style={{marginLeft: '10rem'}}>
            {/* <b>Organize your teamwork, too</b> */}
            <h3>Prioritize What Matters Most</h3>
            <div className='line'></div>
            <p>Attain the mental clarity you've been seeking. TaskTicks automatically organizes your tasks into Today, Upcoming, and custom Filter views, empowering you to focus on your highest priorities.</p>
            <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link></div>
     
          </div>
          <div className='rightSide' style={{textAlign: 'right'}}>
             <img src='../images/Home/Group 2115.png' style={{width: '75%'}}></img>
          </div>
        </div>
        <div className='info1' style={{marginBottom: '20px'}}>
        <div className='rightSide' style={{textAlign: 'left'}}>
          <img src='../images/Home/Group 2116.png' style={{width: '75%'}}></img>
          </div>
          <div className='leftSide' style={{marginRight: '10rem'}}>
            {/* <b>Organize your teamwork, too</b> */}
            <h3>Teamwork, Organized and Efficient</h3>
            <div className='line'></div>
            <p>Harmonize your team's workflow in one unified space. TaskTicks offers a collaborative platform for your team's tasks and projects, perfectly balanced with your personal to-dos.</p>
            <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link></div>
     
          </div>
          
        </div>
        <div className='fullWidth' style={{textAlign: 'center', padding: '30px 10px 50px 10px'}}>
          <h1 className='mb-0'> <b>As Simple or Complex as You Need</b></h1>
          <p className='font_light sub_title'>From basic task entry to advanced project management</p>
          {/* <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link> */}
          <div className='PointsSection'>
          <ul className='rowUlPoints'>
            <li>Easily add and break down tasks into subtasks.</li>
            <li>Organize tasks into distinct projects.</li>
            <li>Share projects and collaborate effortlessly.</li>
            <li>Prioritize tasks with custom levels.</li>
            <li>Set reminders and never miss a deadline.</li>
            <li>Enhance your experience with various extensions.</li>
            <li>Create custom filters for personalized views.</li>
            <li>Enjoy the satisfaction of completing tasks.</li>
          </ul>
        </div>
        <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link>
    
        </div>
        
        
       </div>

       <div className='fullWidth' style={{textAlign: 'center', padding: '30px 10px 50px 10px'}}>
          <h1 className='mb-0'>What Our Users Say About TaskTicks</h1>
          <p className='font_light sub_title'>Experiencing TaskTicks first-hand is different for everyone, but the impact is universally positive. Don’t just take our word for it - hear it from those who have transformed their productivity with us.</p>
          {/* <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7', marginTop: '30px'}}>Start for free</Button></Link> */}
        </div>
        <div className='container mx-auto py-10 width1200'>
        <div className='row-inline'>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Alex J., Project Manager
            </div>
           <h3> <b>A Game-Changer for Our Workflow</b></h3>
           <p>As a project manager, I've tried various tools, but TaskTicks stands out. It's intuitive and aligns perfectly with our team's workflow. Our efficiency has skyrocketed since we started using it.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Sarah L., Freelance Writer
            </div>
           <h3> <b> Simplified My Daily Routines</b></h3>
           <p>TaskTicks has been a lifesaver for organizing both my professional and personal life. Its easy-to-use interface and powerful features like recurring tasks and reminders have simplified my daily routines.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Mike D., Tech Startup CEO
            </div>
           <h3> <b>Enhanced Team Collaboration</b></h3>
           <p>Our team collaboration has never been smoother. TaskTicks makes it easy to share tasks, set deadlines, and communicate efficiently. It's a must-have tool for any team looking to improve productivity.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Emily R., Student
            </div>
           <h3> <b>Streamlined and Stress-Free</b></h3>
           <p>I always struggled with keeping track of my tasks and deadlines. TaskTicks changed that. Now, everything is streamlined and stress-free. I can focus on the task at hand without worrying about forgetting something important.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @David K., Digital Marketer
            </div>
           <h3> <b>Customizable and Adaptable</b></h3>
           <p>What I love most about TaskTicks is its adaptability. I can customize it to fit the specific needs of my projects. It’s not just a task manager; it's a personal productivity assistant.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Anita G., HR Manager
            </div>
           <h3><b>Invaluable Tool for Remote Work</b></h3>
           <p>In the era of remote work, TaskTicks has become an invaluable tool for our team. It keeps us aligned and productive, regardless of where we are.</p>

          </div>
          <p style={{padding: '20px 20px 0px 20px', textAlign: 'center'}} className='font_light sub_title'>Want to see how TaskTicks can transform your productivity? Join the community of satisfied users and take the first step towards streamlined task management.</p>
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important', marginBottom: '30px'}}>Start for free</Button></Link>
      
        </div>
         
        {/* <div className='fullWidth' style={{textAlign: 'center', padding: '30px 0px 50px 0px'}}>
          <h1 className='mb-0'> Read Our Blog</h1>
          <p className='font_light sub_title'>Get expert tips, in-depth guides, and real-world inspiration to help you manage your tasks, organize your projects, and achieve your goals. Our blog covers everything from time management techniques to workflow optimization and collaboration strategies.</p>
          <Link to='/'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7', marginTop: '30px'}}>Explore the Blog</Button></Link>
        </div> */}
        </div>
    </div>
     
  )
}

export default HomeBody
