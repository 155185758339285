import React, {useState, useEffect} from 'react';
import Header from '../Header/Header';
import Footer from '../Home/Footer'
import Grid from '@mui/material/Unstable_Grid2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Feature = () => {
  const [buttonStates, setButtonStates] = useState();






   const expandCollapse = (id) => {
      
      setButtonStates(id);
   }

  return (
    <div>
    <Header/>
     <div className='container mx-auto py-10 width1200'>
      <div className='HeadlineABt'>
        <h1 className='mb-0'>From <s style={{color: '#000000'}}>Overwhelmed</s> <br/> to In-Control</h1>
        <p className='font_light sub_title'>Experience seamless organization of your tasks and projects with TaskTicks, your all-in-one task management solution.</p>
      </div>
      <div className="mt-7">
        <Grid container spacing={2} className='responsiveRowcnt'>
        <Grid md={6} xs={12} sx={{position: 'relative'}}>
           <div className='widthMax'>
           <h2 className='font_size_feSubTle'>Never Miss an Idea <br /> or Deadline</h2>
           <p className='font_light sub_title'>TaskTicks makes adding tasks lightning-fast and easy, so you can focus on what matters.</p>
           <Accordion className={buttonStates === 'panel1a-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={()=> expandCollapse('panel1a-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Quick Add Magic</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Instantly capture tasks as they occur to you, in your own words.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2a-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          onClick={()=> expandCollapse('panel2a-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Recurring Due Dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Effortlessly set recurring deadlines that work for YOU.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3a-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          onClick={()=> expandCollapse('panel3a-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Reliable Reminders</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Customizable notifications so you never miss a beat.

          </Typography>
        </AccordionDetails>
      </Accordion>
      
           </div>
           <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
        </Grid>
       
        <Grid md={6} xs={12} className="marginBtmAdj">
           <img className='img_fluid' src='./images/fe1.PNG' />
        </Grid>
        </Grid>
        <div className='fullWidth' style={{textAlign: 'center', padding: '30px 0px 50px 0px'}}>
          <h1 className='mb-0'>Synchronized, Wherever You Are</h1>
          <p className='font_light sub_title'>Experience seamless organization of your tasks and projects with TaskTicks, <br /> your all-in-one task management solution.</p>
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link>
        </div>

        <Grid container spacing={2} className='responsiveRowimg'>
         <Grid md={6} xs={12} className="marginBtmAdj">
           <img className='img_fluid' src='./images/fitness.PNG' />
        </Grid>
        <Grid md={6} xs={12} sx={{position: 'relative'}}>
           <div className='widthMax'>
           <h2 className='font_size_feSubTle'>Simplify, Prioritize,  <br/> and Achieve</h2>
           <p className='font_light sub_title'>Don’t let simplicity deceive you - TaskTicks is equipped with all the tools for comprehensive workflow management.</p>
           <Accordion className={buttonStates === 'panel1b-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1b-content"
          id="panel1b-header"
          onClick={()=> expandCollapse('panel1b-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Organize and track all aspects of your life and work.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2b-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2b-content"
          id="panel2b-header"
          onClick={()=> expandCollapse('panel2b-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Priorities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Easily identify urgent tasks.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3b-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3b-content"
          id="panel3b-header"
          onClick={()=> expandCollapse('panel3b-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Labels</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Categorize and group tasks across different projects.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3b-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4b-content"
          id="panel4b-header"
          onClick={()=> expandCollapse('panel4b-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Task Descriptions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Keep essential information and links within easy reach.


          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel5b-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5b-content"
          id="panel5b-header"
          onClick={()=> expandCollapse('panel5b-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Sections & Sub-tasks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Break down large tasks into manageable steps.


          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
           </div>
        </Grid>
        </Grid>
        <Grid container spacing={2} className='responsiveRowcnt'>
        <Grid md={6}  sx={{position: 'relative'}}>
           <div className='widthMax'>
           <h2 className='font_size_feSubTle'> Focus Where It Matters</h2>
           <p className='font_light sub_title'>TaskTicks offers flexible views to highlight tasks that need your attention now, setting aside the rest for later.</p>
           <Accordion className={buttonStates === 'panel1c-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={()=> expandCollapse('panel1c-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Upcoming</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Get an overview of your schedule and easily rearrange tasks.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2c-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2c-content"
          id="panel2c-header"
          onClick={()=> expandCollapse('panel2c-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Today</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Focus on today's tasks for a fulfilling end-of-day achievement.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3c-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3c-content"
          id="panel3c-header"
          onClick={()=> expandCollapse('panel3c-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Customize views to your liking, from urgent tasks to specific project categories.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
           </div>
        </Grid>
        <Grid md={6} xs={12} className="marginBtmAdj">
           <img className='img_fluid' src='./images/upcoming.PNG' />
        </Grid>
         
         
       
        </Grid>

        <div className='fullWidth' style={{textAlign: 'center', padding: '30px 0px 50px 0px'}}>
          <h1 className='mb-0'> Same Project, Flexible Views</h1>
          <p className='font_light sub_title'>Choose between list, calendar, or board views to best suit your project planning and tracking needs.</p>
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link>
        </div>


        <Grid container spacing={2} className='responsiveRowimg'>
        <Grid md={6} xs={12} className="marginBtmAdj" >
        <img className='img_fluid' src='./images/upcoming.PNG' />
        </Grid>
        <Grid md={6} xs={12} sx={{position: 'relative'}}>
        <div className='widthMax'>
           <h2 className='font_size_feSubTle'> Collaboration Made Effortless</h2>
           <p className='font_light sub_title'>TaskTicks bridges gaps, ensuring seamless task sharing and collaboration across devices and teams.</p>
           <Accordion className={buttonStates === 'panel1d-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
          onClick={()=> expandCollapse('panel1d-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Shared Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Divide and conquer tasks efficiently.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2d-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
          onClick={()=> expandCollapse('panel2d-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Assigned Tasks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Clear delegation for accountability.


          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3d-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
          onClick={()=> expandCollapse('panel3d-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Comments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Discuss, attach files, and even send voice notes within tasks.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
           </div>
        </Grid>
         
         
       
        </Grid>
        <Grid container spacing={2} className='responsiveRowcnt'>
        <Grid md={6} xs={12} sx={{position: 'relative'}}>
           <div className='widthMax' >
           <h2 className='font_size_feSubTle'> Empower Your Team with TaskTicks</h2>
           <p className='font_light sub_title'>Join hundreds of teams who rely on TaskTicks for streamlined, collaborative task management.</p>
           <Accordion className={buttonStates === 'panel1e-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1e-content"
          id="panel1e-header"
          onClick={()=> expandCollapse('panel1e-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Shared Workspace</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Collaborate effectively while keeping personal tasks private.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2e-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2e-content"
          id="panel2e-header"
          onClick={()=> expandCollapse('panel2e-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Public and Private Team Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Flexible sharing for varied team needs.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3e-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3e-content"
          id="panel3e-header"
          onClick={()=> expandCollapse('panel3e-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Project Sharing via Link</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Easy access to public team projects.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel4e-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4e-content"
          id="panel4e-header"
          onClick={()=> expandCollapse('panel4e-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Filter by Project Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Toggle between team and personal tasks effortlessly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel5e-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5e-content"
          id="panel5e-header"
          onClick={()=> expandCollapse('panel5e-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Roles and Permissions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Manage access and control within team projects.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
           </div>
        </Grid>
        <Grid md={6} xs={12} className="marginBtmAdj">
           <img className='img_fluid' src='./images/upcoming.PNG' />
        </Grid>
         
         
       
        </Grid>
        <div className='fullWidth' style={{textAlign: 'center', padding: '30px 0px 50px 0px'}}>
          <h1 className='mb-0'>Elevate Teamwork with Templates</h1>
          {/* <p className='font_light sub_title'>Choose between list, calendar, or board views to best suit your project planning and tracking needs.</p> */}
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7', marginTop: '30px'}}>Start for free</Button></Link>
        </div>

        <Grid container spacing={2} className='responsiveRowimg'>
        <Grid md={6} xs={12} className="marginBtmAdj">
           <img className='img_fluid' src='./images/upcoming.PNG' />
        </Grid>
        <Grid md={6} xs={12} sx={{position: 'relative'}}>
           <div className='widthMax'>
           <h2 className='font_size_feSubTle'> Track and Celebrate Your Progress</h2>
           <p className='font_light sub_title'> Visualize your journey with TaskTicks, setting and achieving daily and weekly goals.</p>
           <Accordion className={buttonStates === 'panel1f-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1f-content"
          id="panel1f-header"
          onClick={()=> expandCollapse('panel1f-header')}
          
        >
          <AddIcon sx={{marginRight: '20px', color: '#ccc'}}/> <Typography sx={{fontWeight: '700'}}> Productivity Visualization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Track progress over time.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel2f-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2f-content"
          id="panel2f-header"
          onClick={()=> expandCollapse('panel2f-header')}
          
        >
          <CachedIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Activity History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A comprehensive look at your project and personal activity.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel3f-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3f-content"
          id="panel3f-header"
          onClick={()=> expandCollapse('panel3f-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>Completed Tasks Archive</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A testament to your accomplishments.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={buttonStates === 'panel4f-header' ? 'accBg' : 'defBg'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4f-content"
          id="panel4f-header"
          onClick={()=> expandCollapse('panel4f-header')}
          
        >
          <AccessAlarmIcon sx={{marginRight: '20px', color: '#ccc'}}/><Typography sx={{fontWeight: '700'}}>TaskTicks Karma</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Rewarding you for every completed task and feature utilization.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <div className='CTAButton'><Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link></div>
           </div>
        </Grid>
        
         
         
       
        </Grid>

        <div className='fullWidth' style={{textAlign: 'center', padding: '30px 0px 50px 0px'}}>
          <h1 className='mb-0'>What Our Users Say About TaskTicks</h1>
          <p className='font_light sub_title'>Experiencing TaskTicks first-hand is different for everyone, but the impact is universally positive. Don’t just take our word for it - hear it from those who have transformed their productivity with us.</p>
          {/* <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7', marginTop: '30px'}}>Start for free</Button></Link> */}
        </div>
        
        <div className='row-inline'>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Alex J., Project Manager
            </div>
           <h3> <b>A Game-Changer for Our Workflow</b></h3>
           <p>As a project manager, I've tried various tools, but TaskTicks stands out. It's intuitive and aligns perfectly with our team's workflow. Our efficiency has skyrocketed since we started using it.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Sarah L., Freelance Writer
            </div>
           <h3> <b> Simplified My Daily Routines</b></h3>
           <p>TaskTicks has been a lifesaver for organizing both my professional and personal life. Its easy-to-use interface and powerful features like recurring tasks and reminders have simplified my daily routines.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Mike D., Tech Startup CEO
            </div>
           <h3> <b>Enhanced Team Collaboration</b></h3>
           <p>Our team collaboration has never been smoother. TaskTicks makes it easy to share tasks, set deadlines, and communicate efficiently. It's a must-have tool for any team looking to improve productivity.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Emily R., Student
            </div>
           <h3> <b>Streamlined and Stress-Free</b></h3>
           <p>I always struggled with keeping track of my tasks and deadlines. TaskTicks changed that. Now, everything is streamlined and stress-free. I can focus on the task at hand without worrying about forgetting something important.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @David K., Digital Marketer
            </div>
           <h3> <b>Customizable and Adaptable</b></h3>
           <p>What I love most about TaskTicks is its adaptability. I can customize it to fit the specific needs of my projects. It’s not just a task manager; it's a personal productivity assistant.</p>

          </div>
          <div className='csWidth'>
            <div className='imageprofile'>
              <img src='../images/dummyProfilePic.png'/> @Anita G., HR Manager
            </div>
           <h3><b>Invaluable Tool for Remote Work</b></h3>
           <p>In the era of remote work, TaskTicks has become an invaluable tool for our team. It keeps us aligned and productive, regardless of where we are.</p>

          </div>
          <p style={{padding: '20px 20px 0px 20px', textAlign: 'center'}} className='font_light sub_title'>Want to see how TaskTicks can transform your productivity? Join the community of satisfied users and take the first step towards streamlined task management.</p>
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7', marginBottom: '30px'}}>Start for free</Button></Link>
      
        </div>
         
     </div>
     </div>
     <Footer />
    </div>
  )
}

export default Feature
