import React, { useContext } from 'react';
import { useEffect, useState, useRef  } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Button from '@mui/material/Button';
import AddTask from './AddTask';
import Myaccount from '../profile/Myaccount';
import './Dashboard.css'
import { db } from '../auth/config';
import {collection, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';
import AuthContext from '../auth/Auth-context';
import AddIcon from '@mui/icons-material/Add';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import EventAvailableSharpIcon from '@mui/icons-material/EventAvailableSharp';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import IconButton from '@mui/material/IconButton';
import CalendarView from './CalendarView';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Dashboard.css';
import SlidebarHeader from './SlidebarHeader';


const Main = (props) => {
  const [screenheight, setscreenheight] = useState();
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const usercollRef = collection(db, 'Users'); 
  const audioRef = useRef(null);
  const audioRefDel = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [category, setCategory] = useState('');
  const [completedTaskList, setCompletedTaskList] = useState([]);
  const [emailID, setEmailID] = useState();
  const groupedData = {};
  const [calenderData, setCalenderData] = useState([])
  const [inboxCount, setInboxCount] = useState();
  const [todayCountoverDueCount, settodayCountoverDueCount] = useState();
  const [todayCount, setTodayCount] = useState();
  const [upcomingData, setUpcomingData] = useState();
  const [menuOpen, setMenuopen] = useState(true);
  const [xsValue, setxsValue] = useState(6);
  const [mdValue, setmdValue] = useState(10);
  const history = useNavigate();
 

  console.log(props.myAccount)
  useEffect(()=> {
    
    if(props.myAccount) {
      setCategory(props.myAccount);
    }
    
  })

  useEffect(()=> {
    let width = window.innerWidth;
    console.log(width)
    if(width < 900){
      console.log(false);
      setMenuopen(false)
    }
    else {
      console.log(true);
      setMenuopen(true)
    }
  }, [])

  useEffect(() => {     
    const email = localStorage.getItem('email');
    setEmailID(email);
    setscreenheight(window.innerHeight -1 +'px')
    getUsers(email);
    // sendEmail();
    let filterById = JSON.parse(localStorage.getItem('completedtask'));
    console.log(filterById);
    filterById = filterById ? filterById.filter(item => item.email === email) : "";
    setCompletedTaskList(filterById);
  }, [authCtx])

  useEffect(()=> {
    sideButtonsActive('Dashboard', 'onload');
  },[])
const getUsers = async(email, id) => { 
 const q = query(usercollRef, where('Email', '==', email), orderBy('created', 'desc'));
 let data = await getDocs(q);
 data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
 console.log(data)
 setCalenderData(data)
 setInboxCount(data.length);
 var today = new Date();
 today.setHours(0, 0, 0, 0); // Set the time to midnight for comparison

// Use Array.prototype.filter to filter the array by comparing DueDate with today
var filteredData = data.filter(item => {
    const dueDate = new Date(item.DueDate);
    dueDate.setHours(0, 0, 0, 0); // Set the time to midnight for comparison
    console.log(dueDate.getTime())
    return dueDate.getTime() === today.getTime(); // Compare dates
});
var filteredDataByDueDate = data.filter(item => {
  const dueDate = new Date(item.DueDate);
  dueDate.setHours(0, 0, 0, 0); // Set the time to midnight for comparison
  return today.getTime() > dueDate.getTime() ; // Compare dates
});
var filteredUpcoming = data.filter(item => {
  const dueDate = new Date(item.DueDate);
  dueDate.setHours(0, 0, 0, 0); // Set the time to midnight for comparison
  return today.getTime() < dueDate.getTime() ; // Compare dates
});


 
setUpcomingData(filteredUpcoming.length)
settodayCountoverDueCount(filteredData.length + filteredDataByDueDate.length);
setTodayCount(filteredData.length);
 // Iterate through the array and group objects by created date
data.forEach(item => {
  // Convert the created date to a string (without time)
  const createdDate = new Date(item.created.seconds * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
  });

  // If the date is not a key in the groupedData object, create an empty array for it
  if (!groupedData[createdDate]) {
      groupedData[createdDate] = [];
  }

  // Push the item into the corresponding date's array
  groupedData[createdDate].push(item);
});
// console.log(groupedData);
// Get an array of dates using Object.keys()
const dates = Object.keys(groupedData);

// Use map() to access each group of items
const groupedItems = dates.map(date => ({
    date,
    items: groupedData[date]
}));
//  console.log(groupedItems)
 setUsers(groupedItems);
 setIsHovered(false);
if(id){
  document.getElementById('icon'+id).style.visibility = 'hidden';
  document.getElementById(id).style.transform = '';
}
//  data = data.filter((value) => value.Email === email); 
};
 
const formatDate = (inputDate) => {
  const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
  const date = new Date(inputDate);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const formatDateOnlyWeek = (inputDate) => {
  const options = { weekday: 'long'};
  const date = new Date(inputDate);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const addTaskHandler = (bool, type) => {
  // setOpen(true);
  authCtx.addTask(bool, type);
  audioRef.current.play();
}

const playAudio = () => {
  audioRef.current.play();
   
};

const deleteHandlerChange = async(id, title, descript, DueDate) => {
  setIsHovered(true);
  console.log(id, title, descript, DueDate);
  var previousJsonArray = localStorage.getItem('completedtask');
  var previousArray = JSON.parse(previousJsonArray) || [];
  var CurrentdeleteArray =  [{
    "Id": id,
    "title": title,
    "Description": descript,
    "DueDate": DueDate,
    "CompletedOn": new Date(),
    "email" : emailID
  }]
  
  var mergedArray = previousArray.concat(CurrentdeleteArray);
  var mergedJsonArray = JSON.stringify(mergedArray);
  localStorage.setItem('completedtask', mergedJsonArray);
    const userDoc = doc(db, 'Users', id);
    document.getElementById('icon'+id).style.visibility = 'visible';
    document.getElementById(id).style.transform = 'scale(1.2)';
    // document.getElementById(id).style.transform = 'scale(1.1)';
    // document.getElementById('icon'+id).style.transform = 'scale(1.1)';
    audioRefDel.current.play();
    await deleteDoc(userDoc);
    const email = localStorage.getItem('email');
    getUsers(email, id);
    
    let filterById = JSON.parse(localStorage.getItem('completedtask'));
    console.log(filterById);
    filterById = filterById ? filterById.filter(item => item.email === email) : "";
    setCompletedTaskList(filterById);
    authCtx.isDeleteHandler(true);
}

const handleMouseOver = (id) => {
 
  // console.log('icon'+id);
 document.getElementById('icon'+id).style.visibility = 'visible';
 document.getElementById(id).style.transform = 'scale(1.2)';
};

const handleMouseOut = (id) => {
 
  // console.log('icon'+id);
  if(isHovered){

  }else{
    document.getElementById('icon'+id).style.visibility = 'hidden';
    document.getElementById(id).style.transform = '';
  }
 
};

const sideButtonsActive =(category, type)=> {
  setCategory(category);
  history('/dashboard');
  props.returnPassMyAcc(false);
  if(!type){
    playAudio();    
  }
  if(category === 'Completed'){
    
     console.log(completedTaskList);
    
  }
   
}
const dateString = (dateString) => {
  var date = new Date(dateString);

  // Define arrays for the days of the week and months
  var daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  // Extract the day, month, and year components from the Date object
  var dayOfWeek = daysOfWeek[date.getUTCDay()];
  var month = months[date.getUTCMonth()];
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();
  
  // Format the date in the desired format
  var formattedDate = dayOfWeek + ', ' + month + ' ' + day + ', ' + year;
  return formattedDate;
}

const editHander = (id, title, desc, duedate, priority, type, bool) => {
   
  // console.log(id, title, desc, duedate, priority, type);
  authCtx.editTask(bool, type, id, title, desc, duedate, priority);
  audioRef.current.play();

}
// console.log(users);


const sendEmail = () => {
  fetch('http://localhost:3001/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: 'bellaryharsha2017@gmail.com',
      subject: 'Its a Schedule Mail of TaskTicks Due task Notifications',
      text: 'You have 5 Pending Tasks Please complete',
    }),
  })
  // Its a Schedule Mail of TaskTicks Due task Notifications
    .then((response) => {
      if (response.status === 200) {
        console.log('Email sent successfully');
      } else {
        console.error('Error sending email');
      }
    })
    .catch((error) => {
      console.error('Network error:', error);
    });
};

const openMenu = (status) => {
 if(status){
  setMenuopen(false)
  setxsValue(11)
 }
 else {
  setMenuopen(true)
  setxsValue(6)
}
}

  return (
    <div style={{position: 'relative'}}>
     <AddTask />
     {menuOpen ? "" : <ul className='ulSideBtn'>
      <li className='hamberPosAbs' onClick={()=> openMenu(false)}><IconButton title='Open/close sidebar'><img src='/images/sideBar.png'  style={{width: '25px',textAlign: "right", cursor: "pointer"}} /></IconButton></li>
      </ul>}
    <div className="mbStyle row" style={{ flexGrow: 1, margin: 0, width: '100%', zIndex: 9, position: 'relative', transition: '0.2s', height: screenheight ? screenheight : '' }}>
     
      {menuOpen ? <div className="mbslideAbs col-md-2" xs={6} md={2} style={{marginRight: '10px', background: 'linear-gradient(171.09deg, #E0D8FF 11.64%, #FFD8FD 88.69%)', transition: '0.2s'}}>
         <ul className='ulSideBtn'>
          
         <li style={{position: 'relative', textAlign: 'right'}} ><SlidebarHeader/><IconButton onClick={()=> openMenu(true)} title='Open/close sidebar' sx={{width: 'inherit!important'}}><img src='/images/sideBar.png'  style={{width: '25px',textAlign: "right", cursor: "pointer"}} /></IconButton></li>
         <li className={category === 'Dashboard' ? 'tabActive' : ''} onClick={()=> {sideButtonsActive('Dashboard')}}><Button color="secondary"><img src='/images/icons/Group 1792.png' style={{marginRight: '10px', width: '25px'}}/> Dashboard <span className='countSpan'> </span></Button></li>
         <li className={category === 'Inbox' ? 'tabActive' : ''} onClick={()=> {sideButtonsActive('Inbox')}}><Button color="secondary"><img src='/images/icons/Group 1791.png' style={{marginRight: '10px', width: '25px'}}/> Inbox <span className='countSpan'>{inboxCount ? inboxCount : 0}</span></Button></li>
         <li className={category === 'Today' ? 'tabActive' : ''} onClick={()=> {sideButtonsActive('Today')}}><Button color="secondary"><img src='/images/icons/Group 1790.png' style={{marginRight: '10px', width: '25px'}}/> Today <span className='countSpan' style={{color: "#800080"}}>{todayCountoverDueCount ? todayCountoverDueCount : 0}</span></Button></li>
         <li className={category === 'Upcoming' ? 'tabActive' : ''} onClick={()=> {sideButtonsActive('Upcoming')}}><Button color="secondary"><img src='/images/icons/Group 1789.png' style={{marginRight: '10px', width: '25px'}}/> Upcoming  <span className='countSpan' style={{color: "#4b9244"}}>{upcomingData ? upcomingData : 0}</span></Button></li>
         <li className={category === 'Completed' ? 'tabActive' : ''} onClick={()=> {sideButtonsActive('Completed')}}><Button color="secondary"><img src='/images/icons/Group 1417.png' style={{marginRight: '10px', width: '25px'}}/> Completed <span className='countSpan' style={{color: "orange"}}>{completedTaskList ? completedTaskList.length : 0}</span></Button></li>
         <li><Button size="large" onClick={() => {addTaskHandler(true, 'add')}}>
         <AddIcon sx={{color:"#444", marginRight: '10px'}} /> Add Task </Button>
         </li>
         

         
         </ul>
      </div>: ''}
      
      {
        category === 'Dashboard' ? <div className="mbRightside col-md-9" xs={xsValue} md={mdValue} style={{background: '#fff', transition: '0.2s', marginTop: '80px'}}>
        
        <div className=''>
        
        <h3 className='mbRedSpace' style={{fontSize: '20px'}}>Dashboard</h3>
        <div className='dBlock'>
         <div className='row'>
          <div className='col-md-6'>
          <div className='cardRes'>
          <div><img src='/images/icons/Group 529.png' style={{marginRight: '10px', width: '40px'}}/> <b>Total Task</b></div> <span>{inboxCount ? inboxCount + completedTaskList.length : 0 } <KeyboardArrowRightIcon  sx={{fontSize: '1.5rem'}}/> </span>
              </div>
          </div>
          <div className='col-md-6'>
          <div className='cardRes'>
          <div><img src='/images/icons/Layer 13.png' style={{marginRight: '10px', width: '40px'}}/> <b>Today Task</b></div> <span>{todayCount ? todayCount : 0} <KeyboardArrowRightIcon  sx={{fontSize: '1.5rem'}}/> </span>
              </div>
          </div>
          </div>
          <div className='row'>
          <div className='col-md-6'>
          <div className='cardRes'>
          <div><img src='/images/icons/Group 531.png' style={{marginRight: '10px', width: '40px'}}/> <b>Upcoming Task</b></div> <span>{upcomingData ? upcomingData : 0} <KeyboardArrowRightIcon  sx={{fontSize: '1.5rem'}}/> </span>
              </div>
          </div>
          <div className='col-md-6'>
          <div className='cardRes'>
          <div><img src='/images/icons/Group 530.png' style={{marginRight: '10px', width: '40px'}}/> <b>Pending Task</b></div> <span>{todayCountoverDueCount ? todayCountoverDueCount : 0} <KeyboardArrowRightIcon  sx={{fontSize: '1.5rem'}}/> </span>
              </div>
          </div>
          </div>
          <div className='row'>
          <div className='col-md-6'>
          <div className='cardRes'>
          <div><img src='/images/icons/Layer 12.png' style={{marginRight: '10px', width: '40px'}}/> <b>Complete Task</b></div> <span>{completedTaskList ? completedTaskList.length : 0} <KeyboardArrowRightIcon  sx={{fontSize: '1.5rem'}}/> </span>
              </div>
          </div>
           
          </div>


         
        <Button size="medium" onClick={() => {addTaskHandler(true, 'add')}} sx={{textTransform: 'capitalize', lineHeight: '0', color: '#000', marginTop: '20px'}}>
         <AddIcon sx={{color:"#444", marginRight: '5px', verticalAlign: 'middle'}} /> Add Task </Button>
        </div>
        
        </div>
      </div> : ''
      }
   
      {
        category === 'Inbox' ? <div className="mbRightside col-md-9" xs={xsValue} md={mdValue} style={{background: '#fff', transition: '0.2s', marginTop: '80px'}}>
        
        <div className='maxWidth'>
        
        <h3 className='mbRedSpace' style={{fontSize: '20px'}}>Inbox</h3>
        <div className='dBlock'>
       {
        users ? users.map((users, i) => {
          
          let isYesterday = '';
          const todayDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })
          let yesterday = new Date();
           yesterday.setDate(yesterday.getDate() - 1);
           yesterday = yesterday.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })
           
          if(users.date === todayDate){
            isYesterday = "Today"
          }
          else if(users.date === yesterday){
            isYesterday = "Yesterday"
          } else{
            isYesterday = formatDateOnlyWeek(users.date);
          }
        
          return(
            <span key={i}>
               <span>
                       {
                         users.type != 'subtask' ?
                         <div className='DateCategory'>{users.date} . {isYesterday}</div>  : ''
                       }
                 </span>
              
                   
            
              
           
            
            {users.items.map((users, index)=> {
             
              return(
                <div>
                  { users.type != 'subtask' ? 
                  <div key={index} className='cardBlock'>
                  <span id={users.id} className='borderRadi' onClick={() => {deleteHandlerChange(users.id, users.title, users.Description, users.DueDate)}} onMouseOver={() => {handleMouseOver(users.id)}} onMouseOut={()=> {handleMouseOut(users.id)}}><CheckSharpIcon className='iconDefault' id={"icon"+users.id} sx={{fontSize: '16px', color: '#6a6a6a'}}/></span>
                  <div className='editDiv' onClick={()=> {editHander(users.id, users.title, users.Description, users.DueDate, users.Priority,'edit', true)}}>
                  <h4>{users.title} </h4>
                  <p>{users.Description}</p>
                  <small><HistorySharpIcon sx={{color: new Date(todayDate) <= new Date(users.DueDate) ? 'green' : 'red', marginRight: '0px', fontSize: '0.9rem', verticalAlign: 'text-bottom'}}/> {formatDate(users.DueDate)}</small>
                  <BorderColorOutlinedIcon title='Edit' className='editIcon' sx={{fontSize: '1.5rem'}}/>
                  </div>
                  </div> : ''

                  }
                </div>
              )
            })}
            </span>
            
          )
        }) : "" 
       }



         
        <Button size="medium" onClick={() => {addTaskHandler(true, 'add')}} sx={{textTransform: 'capitalize', lineHeight: '0', color: '#000', marginTop: '20px'}}>
         <AddIcon sx={{color:"#444", marginRight: '5px', verticalAlign: 'middle'}} /> Add Task </Button>
        </div>
        
        </div>
      </div> : ''
      }
      
      {
        category === 'Today' ? <div className="mbRightside col-md-9" xs={xsValue} md={10} sx={{background: '#fff', transition: '0.2s'}}>
        <div className='maxWidth'>
        <h3 className='mbRedSpace' style={{fontSize: '20px'}}>Today</h3>
        <div className='dBlock'>
        {  users ? users.map((users, index) => {
            let isYesterday = '';
            const todayDate = new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
            let yesterday = new Date();
             yesterday.setDate(yesterday.getDate() - 1);
             yesterday = yesterday.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
             
            if(users.date === todayDate){
              isYesterday = "Today"
            }
            else if(users.date === yesterday){
              isYesterday = "Yesterday"
            } else{
              isYesterday = formatDateOnlyWeek(users.date);
            }
            // console.log(users.date,todayDate);
            
           return (
              <span key={index}>
              {
                users.date === todayDate ?  <span>
                <div className='DateCategory'>{users.date} . {isYesterday}</div> 
              {users.items.map((users, index)=> {
              
                  return (
                  <div key={index} className='cardBlock'>
           <span id={users.id} className='borderRadi' onClick={() => {deleteHandlerChange(users.id, users.title, users.Description, users.DueDate)}} onMouseOver={() => {handleMouseOver(users.id)}} onMouseOut={()=> {handleMouseOut(users.id)}}><CheckSharpIcon className='iconDefault' id={"icon"+users.id} sx={{fontSize: '16px', color: '#6a6a6a'}}/></span>
           <div className='editDiv' onClick={()=> {editHander(users.id, users.title, users.Description, users.DueDate, users.Priority,'edit', true)}}>
           <h4>{users.title} </h4>
           <p>{users.Description}</p>
           <small><HistorySharpIcon sx={{color: new Date(todayDate) <= new Date(users.DueDate) ? 'green': 'red', marginRight: '0px', fontSize: '1.1rem', verticalAlign: 'text-bottom'}}/> {formatDate(users.DueDate)}</small>
           <BorderColorOutlinedIcon title="Edit" className='editIcon' sx={{fontSize: '1.5rem'}}/>
           </div>
           </div>
                )
              })}
                </span> : ''
              }
              
              </span>
              
            )
          }) : "No Task" 
         }

        {
          //  overDue
        }

   
          
          
           
          
          
          
         
            <span>
            {calenderData.length > 0 ?  <div className='DateCategory'>Overdue</div> : 'No Task Found'}
            
            {calenderData ? calenderData.map((users, index)=> {
              const todayDate = new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
              let yesterday = new Date();
               yesterday.setDate(yesterday.getDate() - 1);
               yesterday = yesterday.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
              var todayDue = new Date(todayDate);
              var alreadyDue = new Date(users.DueDate);
              
                return (
                  <span key={index}>
                  {todayDue > alreadyDue ? <span><div key={index} className='cardBlock'>
                  <span id={users.id} className='borderRadi' onClick={() => {deleteHandlerChange(users.id, users.title, users.Description, users.DueDate)}} onMouseOver={() => {handleMouseOver(users.id)}} onMouseOut={()=> {handleMouseOut(users.id)}} style={{border: new Date(todayDate) <= new Date(users.DueDate) ? "2.3px solid #6a6a6a" : "2.3px solid red", background: new Date(todayDate) <= new Date(users.DueDate) ? "" : "#ff00001a"}}><CheckSharpIcon className='iconDefault' id={"icon"+users.id} sx={{fontSize: '16px', color: new Date(todayDate) <= new Date(users.DueDate) ? '#6a6a6a' : "red"}}/></span>
                  <div className='editDiv' onClick={()=> {editHander(users.id, users.title, users.Description, users.DueDate, users.Priority,'edit', true)}}>
                  <h4>{users.title} </h4>
                  <p>{users.Description}</p>
                  <small><HistorySharpIcon sx={{color: new Date(todayDate) <= new Date(users.DueDate) ? 'green': 'red', marginRight: '0px', fontSize: '1.1rem', verticalAlign: 'text-bottom'}}/> {formatDate(users.DueDate)}</small>
                  <BorderColorOutlinedIcon title="Edit" className='editIcon' sx={{fontSize: '1.5rem'}}/>
                  </div>
                  </div></span> : ''}
                  </span>
                
              )
                }) : 'No Due task'}
               
            
            </span>
            
        
         
       
         
        </div>
        
        </div>
      </div> : ''
      }
      {
        category === 'Upcoming' ? <div className="mbRightside col-md-9" xs={xsValue} md={10} sx={{background: '#fff', transition: '0.2s'}}>
        <div maxWidth="xl">
        <h3 className='mbRedSpace' style={{fontSize: '24px'}}>Upcoming</h3>
         
        <CalendarView eventData = {calenderData}/>
        </div>
      </div> : ''
      }


      {
        category === 'Completed' ? <div className="mbRightside col-md-9" xs={xsValue} md={10} sx={{background: '#fff', transition: '0.2s'}}>
        <div className='maxWidth'>
        <h3 className='mbRedSpace' style={{fontSize: '24px'}}>Completed Tasks</h3>
        <div className='dBlock'>
        {
          completedTaskList ? completedTaskList.map((users, index) => {
            if(users.email === emailID){
              return ( 
                <div key={index} className='cardBlock' style={{borderBottom: '1px solid #eee'}}>
                 
                <h4><s>{users.title}</s></h4>
                <small>Completed on: {dateString(users.CompletedOn)}</small>
                </div>
                )
            } else {
              return  false;
            }
           
          }) : "No Task Found"
        }
         
        </div>
        
        </div>
        
      </div> : ''
      }
      {
        category === 'My account' ? <div className="mbRightside col-md-9" xs={xsValue} md={10} sx={{background: '#fff', transition: '0.2s'}}>
        <Myaccount />
        
      </div> : ''
      }
      
      

      
      
    </div>
     
    <audio ref={audioRef}>
         <source src="./audio/mouse-click-153941.mp3" type="audio/mpeg" />
         Your browser does not support the audio element.
         </audio>
         <audio ref={audioRefDel}>
         <source src="./audio/pop-up-something-160353.mp3" type="audio/mpeg" />
         Your browser does not support the audio element.
         </audio>
   
      
    
      
      
    </div>
  )
}

export default Main
