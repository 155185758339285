import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Footer=(props)=> {
    return (
        <div>
   <footer className='adminFooter' style={{color: '#000000!important'}}>
        
        <div className='secondLayer'>
          <div className='Footerrow'>
            <div className='firstRow'>
            <div className='logoImage'>
            {/* <span className='logoTxt'><Link to='/'><img src='../logoBlack.png' alt='TaskTicks'/><span className='titleSpan' style={{color: '#000000'}}>TaskTicks</span></Link></span>  */}
         </div>
          <article className='footerArticle'>Join millions of people who organize work and life with TaskTicks.</article>
            </div>
            <div className='secondRow'>
             <h4>Features</h4>
             <p><a href='/feature'>How It Works</a></p>
             {/* <p><a href='#'>Upgrade</a></p> */}
           
            </div>
            <div className='thirdRow'>
            <h4>Resources</h4>
            {/* <p><a href='#'>Download Apps</a></p> */}
             {/* <p><a href='/help'>Help Center</a></p>
             <p><a href='#'>Channel Partners</a></p> */}
            </div>
            <div className='fourthRow'>
             
            <h4>Company</h4>
            <p><a href='/about-us'>About Us</a></p>
             <p><a href='/contact-us'>Contact us</a></p>
             {/* <p><a href='#'>Careers</a></p> */}
             <p><a href='/terms-of-service'>Terms of service</a></p>
             <p><a href='/privacy-policy'>Privacy Policy</a></p>
            </div>
            <div className='fifthRow'>
            {/* <ul className="socialIconsUL">
  <li>
    <a className="socialIcons" aria-label="TaskTicks on Twitter" href="https://twitter.com/">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true">
        <path d="M22.393 3.339a8.874 8.874 0 0 1-2.868 1.121A4.452 4.452 0 0 0 16.23 3c-2.49 0-4.512 2.072-4.512 4.628 0 .363.039.715.116 1.054-3.75-.193-7.076-2.034-9.304-4.837a4.711 4.711 0 0 0-.61 2.329c0 1.605.796 3.022 2.008 3.852a4.424 4.424 0 0 1-2.046-.577v.056c0 2.244 1.556 4.115 3.622 4.539a4.305 4.305 0 0 1-1.19.162c-.29 0-.574-.027-.849-.081.575 1.838 2.24 3.177 4.216 3.212A8.91 8.91 0 0 1 1 19.256a12.564 12.564 0 0 0 6.919 2.077c8.303 0 12.842-7.05 12.842-13.167 0-.202-.004-.403-.011-.6A9.269 9.269 0 0 0 23 5.17a8.84 8.84 0 0 1-2.592.729 4.621 4.621 0 0 0 1.985-2.56z"></path>
      </svg>
    </a>
  </li>
  <li>
    <a className="socialIcons" aria-label="TaskTicks on YouTube" href="https://www.youtube.com/channel/">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true">
        <path d="M3.598 4.242C6.958 4 11.995 4 11.995 4h.01s5.038 0 8.396.242l.155.016c.515.05 1.427.138 2.25.995.72.725.954 2.371.954 2.371S24 9.557 24 11.49v1.812c0 1.933-.24 3.866-.24 3.866s-.234 1.646-.954 2.371c-.823.858-1.735.946-2.25.996-.057.005-.108.01-.155.016-3.358.241-8.401.249-8.401.249s-6.24-.057-8.16-.24c-.091-.017-.202-.03-.327-.045-.609-.073-1.562-.187-2.32-.976-.719-.725-.953-2.37-.953-2.37S0 15.234 0 13.301V11.49c0-1.933.24-3.866.24-3.866s.234-1.646.954-2.37c.823-.858 1.735-.947 2.25-.996.057-.006.108-.01.154-.016zm12.408 7.912L9.521 8.787l.001 6.711 6.484-3.344z" fill-rule="evenodd" clip-rule="evenodd"></path>
      </svg>
    </a>
  </li>
  <li>
    <a className="socialIcons" aria-label="TaskTicks on Facebook" href="https://facebook.com/">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true">
        <path d="M23 12.067C23 5.955 18.075 1 12 1S1 5.955 1 12.067C1 17.591 5.023 22.17 10.281 23v-7.734H7.488v-3.199h2.793V9.63c0-2.774 1.643-4.306 4.155-4.306 1.203 0 2.462.216 2.462.216v2.724h-1.387c-1.366 0-1.792.853-1.792 1.73v2.074h3.05l-.487 3.2h-2.563V23C18.977 22.17 23 17.591 23 12.067z"></path>
      </svg>
    </a>
  </li>
  <li>
    <a className="socialIcons" aria-label="TaskTicks on Instagram" href="https://www.instagram.com/">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true">
        <path fill-rule="evenodd" d="M6.608 12a5.392 5.392 0 1 1 10.784 0 5.392 5.392 0 0 1-10.784 0zM12 15.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z" clip-rule="evenodd"></path>
        <path d="M17.605 7.655a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52z"></path>
        <path fill-rule="evenodd" d="M12 1.5c-2.852 0-3.21.012-4.33.063-1.117.051-1.88.229-2.548.488a5.146 5.146 0 0 0-1.86 1.211 5.146 5.146 0 0 0-1.21 1.86c-.26.668-.438 1.431-.489 2.549C1.513 8.79 1.5 9.148 1.5 12c0 2.852.012 3.21.063 4.33.051 1.117.229 1.88.488 2.548.269.69.628 1.276 1.211 1.86.584.583 1.17.942 1.86 1.21.668.26 1.431.438 2.549.489 1.12.05 1.477.063 4.329.063 2.852 0 3.21-.012 4.33-.063 1.117-.051 1.88-.229 2.548-.488a5.149 5.149 0 0 0 1.86-1.211 5.149 5.149 0 0 0 1.21-1.86c.26-.668.438-1.431.489-2.549.05-1.12.063-1.477.063-4.329 0-2.852-.012-3.21-.063-4.33-.051-1.117-.229-1.88-.488-2.548a5.148 5.148 0 0 0-1.211-1.86 5.147 5.147 0 0 0-1.86-1.21c-.668-.26-1.431-.438-2.549-.489C15.21 1.513 14.852 1.5 12 1.5zm0 1.892c2.804 0 3.136.01 4.243.061 1.024.047 1.58.218 1.95.362.49.19.84.418 1.207.785.367.368.595.717.785 1.207.144.37.315.926.362 1.95.05 1.107.061 1.44.061 4.243 0 2.804-.01 3.136-.061 4.243-.047 1.024-.218 1.58-.362 1.95-.19.49-.418.84-.785 1.207a3.254 3.254 0 0 1-1.207.785c-.37.144-.926.315-1.95.362-1.107.05-1.44.061-4.243.061-2.804 0-3.136-.01-4.243-.061-1.024-.047-1.58-.218-1.95-.362-.49-.19-.84-.418-1.207-.785a3.253 3.253 0 0 1-.785-1.207c-.144-.37-.315-.926-.362-1.95-.05-1.107-.061-1.44-.061-4.243 0-2.804.01-3.136.061-4.243.047-1.024.218-1.58.362-1.95.19-.49.418-.84.785-1.207a3.253 3.253 0 0 1 1.207-.785c.37-.144.926-.315 1.95-.362 1.107-.05 1.44-.061 4.243-.061z" clip-rule="evenodd"></path>
      </svg>
    </a>
  </li>
</ul> */}
            </div>
          </div>
        </div>
        <div className='lastLayer'>
          <small style={{textAlign: "left"}}>
          Copyright © 2024. All rights reserved.
          </small>
        </div>
       </footer>
        </div>
    );
}

export default Footer;