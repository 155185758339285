import React, { useState } from 'react';
import DashboardContext from './DashboardContext';

const DashboardProvider = ({ children }) => {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(true);
    const [userInfo, setUserInfo] = useState([]);

    const addItem = (item) => {
        setItems((prevItems) => [...prevItems, item]);
    };

    const removeItem = (index) => {
        setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const updateItem = (index, newItem) => {
        setItems((prevItems) =>
            prevItems.map((item, i) => (i === index ? newItem : item))
        );
    };
    const sideBar = (res) => {
        setOpen(res)
    }
    const getIfuserIsLoggedIn = (res) => {
        console.log(res)
        setUserInfo(res)
    }

    return (
        // <DashboardContext.Provider value={{ items, addItem, removeItem, updateItem }}>
            <DashboardContext.Provider value={{ sideBar, open, userInfo, getIfuserIsLoggedIn }}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;