import React, { useEffect } from 'react';
 
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
 
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import InviteUsers from './InviteUsers';
import { useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PreviewIcon from '@mui/icons-material/Preview';
import WebIcon from '@mui/icons-material/Web';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';



const drawerWidth = 270;

  
function SideButton(props) {
    const [selectedTab, setSelectedTab] = useState('');
    const authctx = useContext(AuthContext)
    const [accessCheck, setAccessCheck] = useState();
    const [open, setOpen] = useState(false);
    const InviteUsers = (value) => {
        alert(value)
        setSelectedTab(value)
       }
     
       const UserManagement = (value) => {
        
         setSelectedTab(value)
       }


       useEffect(()=> {
        // setOpen(true);
        const currentUrl = window.location.href;
        const segments = currentUrl.split('/');

// Get the last segment (after the last '/')
        const extractedValue = segments[segments.length - 1];

        console.log(extractedValue); // Output: "yrr_wundrfly"
        setSelectedTab(extractedValue);
        // console.log(authctx);
        // return false;
        if(authctx.checkAcess){
          // console.log(authctx.checkAcess);
        setAccessCheck(authctx.checkAcess.Level)
        // console.log(authctx.checkAcess.Level)
        setOpen(false);
        } 
       }, [selectedTab, authctx])


    //  console.log(authctx.checkAcess.SitePermission)
    return (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          
          
          <Toolbar />
          <Divider />
          {accessCheck && accessCheck != 'Full Access' ? <List>
          {authctx.checkAcess.SitePermission.map((text, index) => (
              
              
               <ListItem key={text} disablePadding className={selectedTab === text ? 'activeTab' : ''}>                
                
               <Link to={'/admin/'+ text} style={{textDecoration: 'none', display: 'block', width: '100%'}}>
               <ListItemButton>
                 <ListItemIcon sx={{minWidth: '36px'}}>
                   {index === 0 && <InboxIcon />}
                   {index === 1 && <MailIcon />}
                   {index === 2 && <LanguageIcon />}
                   {index === 3 && <OpenInBrowserIcon />}
                   {index === 4 && <PreviewIcon />}
                   {index === 5 && <WebIcon />}
                   {index === 6 && <DomainVerificationIcon />}
                   {index === 7 && <SpaceDashboardIcon />}
                   {index === 8 && <SmartButtonIcon />}
                   {index === 9 && <CallToActionIcon />}
                   {index === 10 && <SpeedIcon />}
                   {index === 11 && <SmartButtonIcon />}
                   {index === 12 && <SmartButtonIcon />}
                   {index === 13 && <MailIcon />}
                   {/* {index % 2 === 1 ? <InboxIcon /> : < />} */}
           
                 </ListItemIcon>
                 <ListItemText primary={text} />
                 </ListItemButton>  
                 </Link>
                          
             </ListItem>      
          )
          )}

          </List>: <List>
            {['Searcholl','yrr_mynewtab', 'yrr_wundrfly', 'Mynewtab', 'wundrfly', 'ollreview', 'vayunewtab', 'searchhello', 'safesearchengine', 'Lukall', 'speedtestgizmo', 'safesearchengine_net', 'safesearchengine_org', 'taskticks'].map((text, index) => (
             
              <ListItem key={text} disablePadding className={selectedTab === text ? 'activeTab' : ''}>                
                
                <Link to={'/admin/'+ text} style={{textDecoration: 'none', display: 'block', width: '100%'}}>
                <ListItemButton>
                  <ListItemIcon sx={{minWidth: '36px'}}>
                    {index === 0 && <InboxIcon />}
                    {index === 1 && <MailIcon />}
                    {index === 2 && <LanguageIcon />}
                    {index === 3 && <OpenInBrowserIcon />}
                    {index === 4 && <PreviewIcon />}
                    {index === 5 && <WebIcon />}
                    {index === 6 && <DomainVerificationIcon />}
                    {index === 7 && <SpaceDashboardIcon />}
                    {index === 8 && <SmartButtonIcon />}
                    {index === 9 && <CallToActionIcon />}
                    {index === 10 && <SpeedIcon />}
                    {index === 11 && <SmartButtonIcon />}
                    {index === 12 && <SmartButtonIcon />}
                    {index === 13 && <MailIcon />}
                    {/* {index % 2 === 1 ? <InboxIcon /> : < />} */}
            
                  </ListItemIcon>
                  <ListItemText primary={text} />
                  </ListItemButton>  
                  </Link>
                           
              </ListItem>
              
            ))}
          </List>  }
          
          <Divider />
          {
            (accessCheck && accessCheck === 'Full Access') ? <List>
            {['Invite_Users', 'User_Management'].map((text, index) => (
              <ListItem key={text} disablePadding className={selectedTab === text ? 'activeTab' : ''}>                
                
              <Link to={'/admin/'+ text} style={{textDecoration: 'none', display: 'block', width: '100%'}}>
              <ListItemButton >
                <ListItemIcon sx={{minWidth: '36px'}}>
                {index === 0 && <PersonAddAltSharpIcon />}
                {index === 1 && <ManageAccountsSharpIcon />}
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  
                </ListItemIcon>
                <ListItemText primary={text === 'Invite_Users'? "Invite users" : 'User Management'} />
                </ListItemButton>  
                </Link>
                         
            </ListItem>
            ))}
          </List> : ''
          }
          
        </Drawer>
    );
}

export default SideButton;