import React from 'react';
import SideButton from './SideButton';
import Header from './Header'
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import Wundrfly_data from './Wundrfly_data';



function Wundrfly(props) {
  
 
  
  const authCtx = useContext(AuthContext);
   
  // console.log(filterTerms)
  return (
      <div> 
           <Header/>
      <SideButton />
      {authCtx.email ? <Wundrfly_data/> : ''} 
      </div>
  );
}

export default Wundrfly;