import React, { useEffect, useState } from 'react';
import Footer from '../Home/Footer';
import Header from '../Header/Header';
import '../subpages/subpages.css';



const Terms = () => {
 
  return (
    <div>
    <Header></Header>
    <div className='parentContainer'>
      
    
    <div className='privacyPageMain wp-pages'>
    <h2 style={{textAlign: 'center', margin: '30px 10px', padding: '50px 0'}}>Terms of Service</h2>
    <p>Thank you for your interest in TaskTicks, a subsidiary of Dijura Pty Ltd ("Dijura", "TaskTicks," "we," "our," or "us").
        Our website at <a href="https://TaskTicks.com">TaskTicks.com</a>, along with related websites, networks, applications,
        mobile applications, and other services provided by us, collectively form the "Service". These Terms of Service constitute
        a legally binding contract between you and TaskTicks regarding your use of the Service.</p>

    <h2>Terms Acceptance</h2>
    <p>Please read these terms carefully. By clicking "I accept," downloading, installing, or accessing or using the Service,
        you acknowledge that you have read, understood, and agreed to these terms and conditions, including TaskTicks' Privacy
        Policy. If you are ineligible or disagree with these Terms, you are not permitted to use the Service.</p>

    <h2>Arbitration Notice</h2>
    <p>For certain disputes, as outlined in Section “Dispute Resolution and Arbitration”, you agree that issues arising under
        these Terms will be resolved through binding, individual arbitration. By accepting these Terms, both you and TaskTicks
        waive the right to a trial by jury or to participate in any class action or representative proceeding.</p>

    <h2>TaskTicks Service Overview</h2>
    <p>TaskTicks offers productivity software services for organizing tasks, projects, and goals. "TaskTicks for Teams" facilitates
        collaborative project management in shared workspaces ("Workspace").</p>

        <h2>Eligibility</h2>
<p>You must be at least 13 years old to use the Service. By agreeing to these Terms, you warrant:</p>
<ol>
    <li>(a) you are at least 13 years old;</li>
    <li>(b) you have not been suspended or removed from the Service; and</li>
    <li>(c) your use complies with all applicable laws and regulations.</li>
</ol>
<p>For entities, organizations, or companies ("Organization"), the individual accepting these Terms on your behalf must have
    the authority to bind you to these Terms.</p>

<h2>Accounts and Registration</h2>
<p>To access the Service, you must register for an account using your Google, Facebook, or Apple account, or create a separate
    account. You must provide accurate information, which you agree to keep up-to-date. You are responsible for maintaining
    the confidentiality of your account and password, and for all activities under your account. Immediately notify us of
    any security breach at <a href="https://taskticks.com/contact">https://taskticks.com/contact</a>.</p>

<h2>General Payment Terms</h2>
<p>Fees may apply for certain Service features. All fees are non-refundable, except as required by law, and detailed on the
    TaskTicks Pricing Page. TaskTicks reserves the right to modify pricing, with advance notice to you. Promotional offers
    may vary and do not necessarily apply to your offer or these Terms.</p>
    <h2>Authorization</h2>
<p>You authorize TaskTicks and third-party payment processors to charge for orders and selected Service levels, including taxes, to your specified payment method. For credit card payments, pre-authorization may be sought to verify available funds.</p>

<h2>Subscription Service</h2>
<p>The Service may include a Subscription Service with automatic periodic charges. By activating this service, you authorize recurring charges until cancellation. Subscription fees are charged on the Subscription Billing Date, as detailed on the Pricing Page. Cancellations must be made before renewal to avoid additional charges.</p>

<h2>Delinquent Accounts</h2>
<p>TaskTicks may suspend or terminate access for unpaid accounts. Delinquent accounts will incur additional charges for chargebacks or collection fees.</p>

<h2>TaskTicks Service Products</h2>
<p>TaskTicks offers various Service products:</p>
<ul>
    <li><strong>TaskTicks Pro:</strong> A paid subscription version with features not guaranteed in the free version. Upgrades are available via the Pricing Page.</li>
    <li><strong>TaskTicks Business:</strong> Manage account access for additional users through an administrative interface. Upgrade options are detailed on the Pricing Page.</li>
    <li><strong>TaskTicks for Teams:</strong> Access collaborative features by creating or joining a Workspace.</li>
</ul>

<h2>Workspace Administration</h2>
<p>The Workspace creator is the default administrator, responsible for managing access and content within the Workspace. Organizational Workspaces are subject to the policies of the respective Organization.</p>

<h2>Beta Services</h2>
<p>Beta Services are provided for evaluation purposes. Usage data collected during this period remains TaskTicks' property. Beta Services are offered "as-is" without warranties.</p>

<h2>Licenses</h2>
<p>TaskTicks grants a limited license for personal or internal business use of the Service, subject to ongoing compliance with these Terms. Restrictions apply, including no modification, distribution, or circumvention of Service features.</p>

<h2>Feedback</h2>
<p>Any feedback provided becomes the property of TaskTicks and can be used for improving the Service.</p>

<h2>Ownership & Proprietary Rights</h2>
<p>All Service-related materials are owned by TaskTicks or its licensors. Users are granted limited rights to use these materials as outlined in these Terms.</p>

<h2>Third Party Terms</h2>
<p>TaskTicks may integrate third-party services and links to third-party websites. Usage of these services and websites is subject to their respective terms and policies.</p>

<h2>User Content</h2>
<p>Users retain rights to their content but grant TaskTicks a license to use it in connection with the Service. TaskTicks is not responsible for User Content and may remove content that violates these Terms.</p>

<h2>Communications</h2>
<p>TaskTicks may send push notifications and emails. Users can opt out of promotional emails.</p>

<h2>Prohibited Conduct</h2>
<p>Users must not use the Service for illegal purposes, violate rights, or engage in harmful activities.</p>

<h2>Copyright Infringement</h2>
<p>TaskTicks complies with the Australian Copyright Act 1968. For copyright complaints, please reach out to us at support@TaskTicks.com</p>

<h2>Modification of Terms</h2>
<p>TaskTicks reserves the right to modify these Terms. Continued use of the Service after changes implies acceptance.</p>

<h2>Term, Termination, and Modification of Service</h2>
<p>These Terms are effective upon acceptance and until terminated. TaskTicks may modify or discontinue the Service at any time.</p>

<h2>Indemnity</h2>
<p>Users will indemnify TaskTicks Entities against all claims and liabilities arising from their use of the Service.</p>

<h2>Disclaimers and No Warranties</h2>
<p>The Service is provided "as is." TaskTicks disclaims all warranties related to the Service.</p>

<h2>Limitation of Liability</h2>
<p>TaskTicks' liability is limited as detailed in these Terms.</p>

<h2>Dispute Resolution and Arbitration</h2>
<p>Disputes will be resolved through arbitration, following the ACICA Rules. Arbitration will take place in Melbourne, Victoria. TaskTicks is not responsible for arbitration costs.</p>

<h2>Miscellaneous</h2>
<p>These Terms, along with the Privacy Policy and other incorporated agreements, constitute the entire agreement between the user and TaskTicks. These Terms are governed by the laws of the State of Victoria, Australia.</p>

<h2>Consent to Electronic Communications</h2>
<p>By using the Service, users consent to receive electronic communications as per the Privacy Policy.</p>

<h2>Contact Information</h2>
<p>TaskTicks can be contacted at <a href="https://TaskTicks.com/contact" target="_blank">https://TaskTicks.com/contact</a>.</p>

<h2>International Use</h2>
<p>The Service, operated from Australia, is designed to cater to an international audience and is available for use globally. However, it is governed exclusively by Australian laws and regulations.</p>

<h2>Notice Regarding Apple</h2>
<p>This section applies to users of the iOS mobile application. Apple is not responsible for the Service.</p>

<p>By using TaskTicks, you agree to these revised Terms of Service.</p>

<h2>Contact Us:</h2>
<p>If you require further information about WundrflyTaskTicks or have any suggestions concerning how to improve our Services or Platforms, please contact our helpdesk. Alternatively, you can write to us at:</p>

<p>
    TaskTicks (A subsidiary of Dijura Pty Ltd)<br />
    ABN: 36 625 628 186<br />
    697 Waverley Road<br />
    Glen Waverley<br />
    VIC 3150<br />
    Australia<br />
    Email: <a href="mailto:support@taskticks.com">support@taskticks.com</a>
   </p>
  
      
   </div>
   </div>
    <Footer />
    </div>
  )
}

export default Terms
