// src/firebase.js
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: 'AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w',
  authDomain: 'todolist-49d4e.firebaseapp.com',
  projectId: 'todolist-49d4e',
  storageBucket: 'todolist-49d4e.appspot.com',
  messagingSenderId: '935240232041',
  appId: '1:935240232041:web:953de49e1aa8e1a57646f8',
  measurementId: "G-NE0P720FR5"
};

const firebaseConfigSearch = {
  apiKey: "AIzaSyDSQMrGf2uIIrfxTV2goCi1fhGFOd5h72U",
  authDomain: "searchengines-9499b.firebaseapp.com",
  projectId: "searchengines-9499b",
  storageBucket: "searchengines-9499b.appspot.com",
  messagingSenderId: "924036855859",
  appId: "1:924036855859:web:112bd5ebb3077fa1de4b46",
  measurementId: "G-88VJ2TTT19"
};

const app = initializeApp(firebaseConfig);
const appSearch = initializeApp(firebaseConfigSearch, 'searchApp');
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const dbSearch = getFirestore(appSearch);

export {auth, provider, db, dbSearch};

 