import React, { useContext, useRef } from "react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import FlagIcon from '@mui/icons-material/Flag';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import AuthContext from "../auth/Auth-context";
import { db } from "../auth/config";
import { collection, getDocs, deleteDoc, addDoc, query, where, doc, orderBy, updateDoc } from "firebase/firestore";
import "./Dashboard.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Menu from "@mui/material/Menu";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

const AddTask = () => {
  const usercollRef = collection(db, "Users");
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(''); 
  const [subtitle, setSubTitle] = useState('');
  const [subdescription, setSubDescription] = useState(''); 
  const [priority, setPriority] = useState("Priority 4"); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [ID, setID] = useState('');
  const openCalenderView = Boolean(anchorEl);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorElP, setAnchorElP] = useState(null);
  const openPriroityView = Boolean(anchorElP);
  const [emailID, setEmailID] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
 
  const [propDate, setpropDate] = useState();
  const [flagColor, setFlagColor] = useState();
  const audioRefsave = useRef(null);
  const [subtask, setSubtask] = useState(false);
  const [minDateForSubTask, setMinDateForSubTask] = useState();
  const [subDate, setsubDate] = useState()
  const [filteredSubtask, setfilteredSubtask] = useState();
  console.log(authCtx);
 

 
 
  const handleClose = (bool) => {
    setOpen(bool);
    authCtx.closePopup(bool);
  };
  useEffect(() => {
    setOpen(authCtx.ispopupOpen);
    console.log(authCtx.ispopupOpen);
    console.log(authCtx.editID);
    if(authCtx.typeIs === 'edit'){
      editSavedTask(authCtx.editData);
    } else {
      setpropDate(null);
      setTitle('');
      setDescription('');
      setPriority('Priority 4');
      setSelectedDate(new Date());

    }
 
  }, [authCtx.ispopupOpen, authCtx.editData, authCtx.editID, authCtx.typeIs]);
  
  const editSavedTask = async(editData) => {
    console.log(editData);
    setTitle(editData.Title);
    setDescription(editData.Description);
    setpropDate(editData.DueDate);
    setSelectedDate(new Date(editData.DueDate));
    setPriority(editData.priority);
    setID(editData.id);
    console.log(editData.id)
    getSubtask(editData.id);
  }
  
 
  
 
  
  const getSubtask = async(editID) => {
    if(editID){
      const q = query(usercollRef, where('type', '==', 'subtask'), orderBy('created', 'desc'));
    let data = await getDocs(q);
    data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
    console.log(data, editID)
    data = data.filter(subtask => subtask.subtaskID === editID);
    console.log(data)
    setfilteredSubtask(data);
    }
  } 

  const updateTask = async(bool) =>{
    const id = authCtx.editData.id;
    const userDoc = doc(usercollRef, id)
    console.log(userDoc);
    await updateDoc(userDoc, {
 
      title: title,
      Description: description,
      DueDate: propDate,
      Priority: priority,
      start: propDate,
      end: propDate 
    })
    audioRefsave.current.play();
    authCtx.closePopup(bool);
    setpropDate(null);
    setTitle('');
    setDescription('');
    setPriority('');
    setSelectedDate(new Date());


  } 

  const saveTaskHandler = async (bool) => {
    
    const email = localStorage.getItem("email");
    const createdOn = new Date();
    // console.log(createdOn.toDateString());
    if(!title){
     alert("Please add the Task title");
     return false;
    } else if(!description) {
      alert("Please add the Task description");
      return false;
    } else if(!propDate) {
      alert("Please set the due date");
      return false;
    } else if(!priority) {
      alert("Please set the priority");
      return false;
    }
    audioRefsave.current.play();
    await addDoc(usercollRef, {
      Email: email,
      title: title,
      Description: description,
      DueDate: propDate,
      Priority: priority,
      created: createdOn,
      start:  formatDate(selectedDate),
      end: formatDate(selectedDate) 
    });
   
    sendEmail(authCtx.email, authCtx.username, title, description, propDate);
    authCtx.closePopup(bool);
    setOpen(bool);
    setpropDate(null);
    setTitle('');
    setDescription('');
    setPriority('');
    setSelectedDate(new Date());
  };

  const addTaskTitleHandler = (event) => {
    setTitle(event.target.value);
  };
  const addTaskDescriptionhandler = (event) => {
    setDescription(event.target.value);
  };
  const handleChange = (event, value) => {
    setAnchorElP(event.currentTarget);
    console.log(value);
    if(value){
       setPriority(value);
    }
    if(value === 'Priority 1'){
      setFlagColor('red')
    } else if(value === 'Priority 2'){
      setFlagColor('orange')
    } else if(value === 'Priority 3'){
      setFlagColor('blue')
    } else if(value === 'Priority 4'){
      setFlagColor('green')
    }
  };
  // console.log(value.format("dddd, D MMMM, YYYY"));
   
  const calenderViewhandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }; 

  const handleCLViewClose = () => {
    setAnchorEl(null);
  };
  const handlePRViewClose = () => {
    setAnchorElP(null);
  };
  const handleAccept = (date) => {
     console.log('Date accepted:', new Date(selectedDate));
    
    setpropDate(formatDate(selectedDate))
    setAnchorEl(null);
    // You can perform any additional actions when the user clicks "OK"
    // setSelectedDate(date);
  };

  const handleDismiss = () => {
    console.log('Date dismissed');
    // You can perform any additional actions when the user clicks "Cancel"
  };
  const formatDate = (inputDate) => {
    const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const date = new Date(inputDate);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  
  const sendEmail = (email, UserName, title, description, dueDate) => {
    console.log(email, UserName, title, dueDate);
    let datecjheck = new Date(dueDate);
    console.log(datecjheck);
    console.log(datecjheck.toISOString().replace(/Z$/, ''));
    console.log(datecjheck.toLocaleString());
    let toLocaleString = datecjheck.toLocaleString();
    const inputDate = new Date(toLocaleString);
    let CheckinputDate = new Date(toLocaleString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    let TodayDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    console.log((TodayDate === CheckinputDate),TodayDate,CheckinputDate);
    inputDate.setDate(inputDate.getDate() + 1);
    const isoString = inputDate.toISOString();
    console.log(isoString);
     let bodyInfo = "<h3>Hello, " + UserName+ '<br> <br> Please complete the below mentioned task before it get Overdue or if you already completed the task then ignore this notification email. <br><br><div className="parent" style="display: block; background: #fbfbfb; padding: 20px;"><div className="title">' +title+ "</div><div className='description' style='font-size: 12px; font-weight: 100; margin: 10px 0;'>" + description + '</div><div style="color: #ffffff;padding: 10px;display: block; text-decoration: none; font-weight: 800; font-family: "Google Sans"; margin-top: 25px; background: rgb(10 77 165); border: 0px solid transparent; color: #fff; border-radius: 20px;"><a href="http://localhost:3000/dashboard">Open your Todolist</a></div> </div><br> Thank you</h3>';
    fetch('http://localhost:3001/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: UserName + " - Your Assigned task(" + title + ") - Overdue",
        html: bodyInfo,
        which: "addTask",
        DueDate: isoString
      }),
    })
    // Its a Schedule Mail of TodoList Due task Notifications
      .then((response) => {
        if (response.status === 200) {
          console.log('Email sent successfully');
        } else {
          console.error('Error sending email');
        }
      })
      .catch((error) => {
        console.error('Network error:', error);
      });
  };
  
  const subTask = (type, res) => {
      // alert(type, id);
      // console.log(type, id);
      setSubtask(res);
      getCurrentDate();
   
  }
  const cancelSubTask = (res) => {
    setSubtask(res);
  }
  const addSubTaskTitleHandler = (event) => {
    setSubTitle(event.target.value);
  }
  const addSubTaskDescriptionhandler = (event) => {
    setSubDescription(event.target.value);
  }
  const addsubDate = (event) => {
    setsubDate(event.target.value);
  }
  const addSubTask = async(id, type) => {
    console.log(id, type);
    const email = localStorage.getItem("email");
    const createdOn = new Date();
    // console.log(createdOn.toDateString());
    if(!subtitle){
      alert("Please add the Task title");
      return false;
    } else if(!subdescription) {
      alert("Please add the Task description");
      return false;
    } else if(!subDate){
      alert("Please select the due date");
      return false;
    }
    audioRefsave.current.play();
    await addDoc(usercollRef, {
      Email: email,
      title: subtitle,
      type: type,
      Description: subdescription,
      DueDate: subDate ? formatDate(subDate) : '',
      created: createdOn,
      subtaskID: id,
      start:  formatDate(subDate),
      end: formatDate(subDate) 
    });
    setSubTitle('');
    setSubDescription('');
    getSubtask(ID);

  }

 
  const getCurrentDate = ()=> {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    setMinDateForSubTask(`${year}-${month}-${day}`);
    
    return `${year}-${month}-${day}`;
  }
  const handleMouseOver = (id) => {
 
    // console.log('icon'+id);
   document.getElementById('icon'+id).style.visibility = 'visible';
   document.getElementById(id).style.transform = 'scale(1.2)';
  };
  
  const handleMouseOut = (id) => {
   
    // console.log('icon'+id);
    if(isHovered){
  
    }else{
      document.getElementById('icon'+id).style.visibility = 'hidden';
      document.getElementById(id).style.transform = '';
    }
   
  };

  const deleteHandlerChange = async(id, title, descript, DueDate) => {
    setIsHovered(true);
    console.log(id, title, descript, DueDate);
    var previousJsonArray = localStorage.getItem('completedtask');
    var previousArray = JSON.parse(previousJsonArray) || [];
    var CurrentdeleteArray =  [{
      "Id": id,
      "title": title,
      "Description": descript,
      "DueDate": DueDate,
      "CompletedOn": new Date(),
      "email" : emailID
    }]
    
    var mergedArray = previousArray.concat(CurrentdeleteArray);
    var mergedJsonArray = JSON.stringify(mergedArray);
    localStorage.setItem('completedtask', mergedJsonArray);
      const userDoc = doc(db, 'Users', id);
      document.getElementById('icon'+id).style.visibility = 'visible';
      document.getElementById(id).style.transform = 'scale(1.2)';
      // document.getElementById(id).style.transform = 'scale(1.1)';
      // document.getElementById('icon'+id).style.transform = 'scale(1.1)';
    
      await deleteDoc(userDoc);
      const email = localStorage.getItem('email');
      setEmailID(email);
     
      
      let filterById = JSON.parse(localStorage.getItem('completedtask'));
      console.log(filterById);
      filterById = filterById ? filterById.filter(item => item.email === email) : "";
    
      authCtx.isDeleteHandler(true);
      getSubtask(ID);
  }
  
  
  const editHander = (id, title, desc, duedate, priority, type, bool) => {
     
    // console.log(id, title, desc, duedate, priority, type);
    authCtx.editTask(bool, type, id, title, desc, duedate, priority);
    audioRefsave.current.play();
  
  }
 
  return (
    <div>
    <audio ref={audioRefsave}>
         <source src="./audio/interface-124464.mp3" type="audio/mpeg" />
         Your browser does not support the audio element.
         </audio>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <input
            type="text"
            onChange={addTaskTitleHandler}
            placeholder="Task Name"
            className="inputForm"
            id="addTaskTitle"
            autoFocus
            value={title}
          />
          <textarea
            type="text"
            onChange={addTaskDescriptionhandler}
            placeholder="Task Description"
            rows="2"
            className="textareaForm"
            id="addTaskDescription"
            value={description}
          />

          <div>
          <span>
          <Button
        id="basic-button"
        aria-controls={openCalenderView ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openCalenderView ? 'true' : undefined}
        onClick={calenderViewhandleClick}
        variant="outlined"
        style={{textTransform: 'math-auto', fontSize: '13px', padding: '5px', lineHeight: 1, border: '1px solid #ccc', margin: '4px'}}
      >  
        {

          propDate ?  <span><InsertInvitationIcon style={{fontSize: '15px',lineHeight: 0,margin: 0, padding: 0,verticalAlign: 'middle'}}/> {propDate}</span> : <span><InsertInvitationIcon style={{fontSize: '15px',lineHeight: 0,margin: 0, padding: 0,verticalAlign: 'middle'}}/> Set date</span>
        }
        </Button>
          </span>
             <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={openCalenderView}
             onClose={handleCLViewClose}
             MenuListProps={{
            'aria-labelledby': 'basic-button',
              }}
            >
            <LocalizationProvider dateAdapter={AdapterDayjs} > 
            <DemoItem>
              <StaticDatePicker
                // onChange={(newValue) => {
                //   setValue(newValue);
                // }}
                minDate={dayjs(new Date())}
                onChange={setSelectedDate}
                defaultValue={dayjs(selectedDate)}
                onAccept={handleAccept}
                onDismiss={handleDismiss}
                renderInput={(params) => <Button {...params} />}                
              />
            </DemoItem>
          </LocalizationProvider>
            </Menu>
            
            <span> 
            <Button
        id = "composition-button"
        aria-controls = {openPriroityView ? 'composition-menu' : undefined}
        aria-haspopup = "true"
        aria-expanded = {openPriroityView ? 'true' : undefined}
        onClick = {handleChange}
        variant = "outlined"
        style={{textTransform: 'math-auto', fontSize: '13px', padding: '5px', lineHeight: 1, margin: '0px 5px', border: '1px solid #ccc'}}
      >  
        {
          priority ? <span><FlagIcon style={{fontSize: '15px', lineHeight: 0, margin: 0, padding: 0, verticalAlign: 'middle', marginRight: '5px', color: flagColor}}/>{priority}</span> : <span><FlagIcon style={{fontSize: '15px', lineHeight: 0, margin: 0, padding: 0, verticalAlign: 'middle', marginRight: '5px'}}/> set priority</span>
        }
        </Button>

        <Menu
             id="composition-menu"
             anchorEl={anchorElP}
             open={openPriroityView}
             onClose={handlePRViewClose}
             
            >
            <MenuItem onClick={()=> handleChange('select','Priority 1')} className="iconSize"><FlagIcon style={{color: 'red', marginRight: '5px'}}/> Priority 1</MenuItem>
            <MenuItem onClick={()=> handleChange('select','Priority 2')} className="iconSize"><FlagIcon style={{color: 'orange', marginRight: '5px'}}/> Priority 2</MenuItem>
            <MenuItem onClick={()=> handleChange('select','Priority 3')} className="iconSize"><FlagIcon style={{color: 'blue', marginRight: '5px'}}/> Priority 3</MenuItem>
            <MenuItem onClick={()=> handleChange('select','Priority 4')} className="iconSize"><FlagIcon style={{color: 'green', marginRight: '5px'}}/> Priority 4</MenuItem>
            </Menu>
            </span>
            
          </div>
          
        </DialogContent>

        <DialogActions style={{borderTop: '1px solid #ccc'}}>
          <Button
            onClick={() => {
              handleClose(false);
            }}
            sx={{textTransform: 'capitalize'}}
          >
            Cancel
          </Button>
          <Button
            onClick={() => { authCtx.typeIs === 'edit'? 
            updateTask(false)  : saveTaskHandler(false)
            }}
         
            variant="contained"
            sx={{ textTransform: "inherit", background: "#2791f7" }}
          >{ authCtx.typeIs === 'edit'? 
          'Update Task'  : 'Add Task'
          }
            
          </Button>
          
          
        </DialogActions>
        {
          filteredSubtask ? <div className="subtaskView" style={{display: 'block'}}>
            <h3>Sub-tasks</h3>
          {  
            filteredSubtask.length > 0 ? filteredSubtask.map((item, index) => {
              console.log(item, index)
                return (
                  <div className="cardBlocksub">
                    <span id={item.id} className='borderRadi' onClick={() => {deleteHandlerChange(item.id, item.title, item.Description, item.DueDate)}} onMouseOver={() => {handleMouseOver(item.id)}} onMouseOut={()=> {handleMouseOut(item.id)}}><CheckSharpIcon className='iconDefault' id={"icon"+item.id} sx={{fontSize: '13px', color: '#6a6a6a', display: 'block'}}/></span>
                    <div className='editDiv'>
                  <h4>{item.title} </h4>
                  <p>{item.Description}</p>
                  <BorderColorOutlinedIcon title='Edit' className='editIcon' sx={{fontSize: '1.5rem'}}/>
                  </div>
                </div>
                )
            }) : ''
          }
          </div> : ''
        }
        
        <div className="addSubTaskBtn">
              <Button onClick={() => {subTask("sub-topic", true)}} sx={{textTransform: 'capitalize', fontSize: '14px'}}>Add Sub-task</Button>
        </div>

        { subtask ? 
          <div>
          <div className="subTaskSection">
            <input
            type="text"
            onChange={addSubTaskTitleHandler}
            placeholder="Task Name"
            className="inputForm"
            id="addSubTaskTitle"
            autoFocus
            value={subtitle}
          />
          <textarea
            type="text"
            onChange={addSubTaskDescriptionhandler}
            placeholder="Task Description"
            rows="2"
            className="textareaForm"
            id="addSubTaskDescription"
            value={subdescription}
          />
           <div className="Subtask-date">Due on:<input min={minDateForSubTask} type="date" onChange={addsubDate} value={subDate} id="Subtask-date" /></div> 
          
          </div>
          <div className="subTaskbutns">
          <Button onClick={()=> cancelSubTask(false)} sx={{textTransform: 'capitalize', fontSize: '12px'}}>Cancel</Button><Button sx={{textTransform: 'capitalize', fontSize: '12px'}} onClick={()=> addSubTask(ID, 'subtask')}>Add task</Button>
          </div>
          </div>  : ''
        }
        
      </Dialog>
    </div>
  );
};

export default AddTask;
