import React from 'react';


import { DataGrid } from "@mui/x-data-grid";
import { dbSearch  } from '../../auth/config';
import { db } from '../../auth/config';
import { collection, getDocs, deleteDoc, doc, query, where, orderBy, updateDoc} from 'firebase/firestore';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AuthContext from '../../auth/Auth-context';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';


const drawerWidth = 270;
const columns = [
    // { field: ' ', headerName: 'ID', width: 300 },
    { field: 'terms', headerName: 'Search Terms', width: 500 },
    { field: 'Date', headerName: 'Date & Time', width: 400 },
    // { field: 'Time', headerName: 'Time', width: 400 }
  ];

function Searcholl_data(props) {
  const [open, setOpen] = React.useState(false);
  // const searchCollectionRef = collection(dbSearch, "Searcholl");
  // const inviteUserCollection = collection(db, "inviteUsers");
  const [searchTerms, setSearchTerms] = useState([]);
  const [filterTerms, setFilterTerms] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // const [selectionModel, setSelectionModel] = useState([]);
  // const [rowSelectionModel, setRowSelectionModel] =  useState([]);
  // const [loadUser, setOnLoaderUser] = useState();
  // const [updateID, setUpdateID] = useState();

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);


  
 
  const authCtx = useContext(AuthContext);
    console.log(authCtx)
    useEffect(()=> {
      // var email = localStorage.getItem('email');
      // setUpdateID(authCtx.email);
      setOpen(true);
      getUserData();
    }, [])
    



    const getUserData = async()=> {

      try {
        const response = await axios.get('https://server.taskticks.com/api/items/searcholl');
        console.log(response.data);
        let modifiedArray = [];
        if(response.data.length > 0) {
          console.log(response.data);
          modifiedArray = response.data.map(item => {
            const date = new Date(item.Date);
            const humanReadableDate = date.toLocaleString('en-IN', {
              timeZone: 'Asia/Kolkata', // Set time zone to IST
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
          });
          
            return {
                id: item._id,
                terms: item.terms,
                Date: humanReadableDate,
                __v: item.__v
            };
            
        });
        }
        console.log(modifiedArray);
        const isOlderThanAWeek = modifiedArray.map(item => {
          let date =  item.Date;
         
          date = date.split(' at ')[0];
          date = new Date(date);
           
          
           
              return {
                  ...item,
                  isOlderThanAWeek: date < oneWeekAgo
              };
      });
      isOlderThanAWeek.map(item => {
                if(item.isOlderThanAWeek){
                  AutoDelFunc(item.id)
                }
      })
        setSearchTerms(modifiedArray);
        setFilterTerms(modifiedArray);
        setOpen(false);
      } catch (error) {
        console.log(error.message);
      }
      // if(updateID){
      //   var getUserID = dataArray.find(item => item.EmailId === updateID)
      //   console.log(dataArray, updateID, getUserID);
      //   getData(getUserID.id);
      // }
      
        
    }

 
   

    
    const AutoDelFunc = async(id) => {
    // var id = '66858f391427cc61da60fb1e';
      try {
             const response = await axios.delete('https://server.taskticks.com/api/items/searcholl/'+id);
             console.log("Deleted Successfully");
             setOpen(false);
            } catch (error) {
              console.error("Error deleting document: ", error);
              setOpen(false);
            }
           
    }
    const searchTermsFilter = async(event) => {
      console.log(event.currentTarget.value);
      let val = event.currentTarget.value; 
      if(val != ''){
          const latestDocuments = searchTerms.filter(item => {
          const itemTerms = item.terms.trim().toLowerCase().replace(/\s/g, '');
          const searchTerm = val.trim().toLowerCase().replace(/\s/g, '');
          return itemTerms.includes(searchTerm);
                    });

                  if(latestDocuments.length === 0){            
                    setFilterTerms(latestDocuments);
                  } else{
                    setFilterTerms(latestDocuments);
                  }

    }   
  else{
  setFilterTerms(searchTerms);
  }

}

useEffect(()=> {
  
let start = null;
let end = null;

          const filtered = searchTerms.filter(item => {

                let itemDate =  item.Date;
                itemDate = itemDate.split(' at ')[0];
                itemDate = new Date(itemDate);

                if(startDate){
                start = startDate ? new Date(startDate) : null;
                // console.log(start);
                const options = { month: 'long', day: 'numeric', year: 'numeric' };
                const formattedDate = start.toLocaleDateString('en-US', options);

                // Specify the time you want
                const time = '00:00:00 AM';
                start =  `${formattedDate} ${time}`
                // console.log(start.getTime())
              }

              if(endDate){
              end = endDate ? new Date(endDate) : null;
              // console.log(end);
              const options1 = { month: 'long', day: 'numeric', year: 'numeric' };
              const formattedDate1 = end.toLocaleDateString('en-US', options1);

              // Specify the time you want
              const time1 = '00:00:00 AM';
              end =  `${formattedDate1} ${time1}`
              }

              // Check if the item date falls within the specified date range

              if (start && end) {
                return new Date(itemDate) >= new Date(start) && itemDate <= new Date(end);
              } else if (start) {
                return new Date(itemDate) >= new Date(start);
              } else if (end) {
                return new Date(itemDate) <= new Date(end);
              }
 
          return true; // If no date range specified, return all
          });
          
          if(startDate && endDate){
            //  console.log(filtered.length)
              if(filtered.length === 0){
                alert('No Search Query found on selected dates'); 
                setStartDate('');
                setEndDate('');
              }
          }

setFilterTerms(filtered);

}, [endDate, startDate])

const ClearDate = () => {
      setStartDate('');
      setEndDate('');
}

  // console.log(filterTerms)
  return (
      <div><Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
       
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}

      >
        <Toolbar style={{background: '#fff'}}>
          <Typography  style={{color: '#333', fontWeight: 'bold'}} variant="h6" noWrap component="div">
            Analytics Tool 
          </Typography>
          {/* <Header /> */}
        </Toolbar>
      </AppBar>
      {/* <SideButton/> */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
          <div className='searchTermsDiv pt-1'>
          <h4><b>Searcholl Search Terms <b style={{float: 'right'}}>Total: {searchTerms ? searchTerms.length: ''}</b> </b></h4>
            
            <div className='filterSection' style={{marginTop: '20px', marginBottom: '10px'}}>
              
            <input style={{padding: '5px 10px 5px 10px', width: '300px', marginRight: '20px'}} type='search'  placeholder='Search' onChange={searchTermsFilter}/>
            {/* <Button onClick={handleDeleteSelectedRows}>Delete Selected Rows</Button> */}
            <div className='dateRange' style={{float: 'right'}}>
            <button onClick={ClearDate} className='btn btn-danger me-2'>Clear</button>
            From: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder='Search' style={{padding: '5px 10px 5px 10px', width: '150px', marginRight: '0px'}}/> To: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} placeholder='Search' style={{padding: '5px 10px 5px 10px', width: '150px', marginRight: '0px'}}/>
            </div>
            </div>
          <DataGrid 
            sx={{minHeight: '200px'}}
            rows={filterTerms && filterTerms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // selectionModel={selectionModel}

            checkboxSelection
            checkboxSelectionVisible={true} // Ensure the "Select All" checkbox is visible
            // checkboxSelectionProps={{
            //   indeterminate: selectionModel.length > 0 && selectionModel.length < searchTerms.length,
            //   checked: selectionModel.length === searchTerms.length,
            //   onChange: (event) => handleSelectAll(event.target.checked),


            // }}
            // onSelectionModelChange={handleSelectionModelChange}
            // onRowSelectionModelChange={(newRowSelectionModel) => {
            //   setRowSelectionModel(newRowSelectionModel);
            // }}
          />
          </div>
        
      </Box>
    </Box>
          
          
      </div>
  );
}



export default Searcholl_data;