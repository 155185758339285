import React, { useContext, useEffect, useRef, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AuthContext from "../auth/Auth-context";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import './SearchView.css';
import './Dashboard.css';
import { db } from '../auth/config';
import {collection, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  


const SearchView = (props) => {
    
    const authctx = useContext(AuthContext);
    const usercollRef = collection(db, 'Users'); 
    
    const [open, setOpen] = useState(false);
    const [matchedData, setMatchedData] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const audioRefDel = useRef(null);
    const audioRef = useRef(null);
    const [completedTaskList, setCompletedTaskList] = useState([]);
    const [searchValue, setSearchValue]= useState('')

    useEffect(()=> {
        setOpen(authctx.SearchViewBool)

        if(authctx.SearchViewBool === 'true'){
          searchQuery();
        }
        
        // if(props.searchOpenIs){
        //     setOpen(props.searchOpenIs)
        // } else if(open === false){
        //     return false;
        // }
        
    },[authctx]);  


     const handleClose = (bool) => {
        
        authctx.searchViewPopupStatus(bool);
     }
     
     const searchQuery = async() => {
      let value = document.getElementById('searchvalue').value;
       console.log(value)
   
      let queryVal = value.split(' ');
      const lowercaseSearchTerms = queryVal.map(term => term.toLowerCase());
      console.log(queryVal, lowercaseSearchTerms, authctx.email);
       const q = query(usercollRef, where('Email', '==', authctx.email), orderBy('created', 'desc'));
       console.log(q);
       try {
        // Use getDocs to retrieve documents
        const querySnapshot = await getDocs(q);
      
        // Find documents that match all search terms
        const matchingDocuments = [];
        querySnapshot.forEach((doc) => {
          const title = doc.data().title.toLowerCase();
          if (lowercaseSearchTerms.every(term => title.includes(term))) {
            matchingDocuments.push({ ...doc.data(), id: doc.id });
          }
        });
        setMatchedData(matchingDocuments);
        // Log the matching documents
        console.log('Matching Documents:', matchingDocuments);
      
      } catch (error) {
        console.error('Error getting documents: ', error);
      }
     }
     const formatDate = (inputDate) => {
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      const date = new Date(inputDate);
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
     
  const deleteHandlerChange = async(id, title, descript, DueDate) => { 
  setIsHovered(true);
  console.log(id, title, descript, DueDate);
  var previousJsonArray = localStorage.getItem('completedtask');
  var previousArray = JSON.parse(previousJsonArray) || [];
  var CurrentdeleteArray =  [{
    "Id": id,
    "title": title,
    "Description": descript,
    "DueDate": DueDate,
    "CompletedOn": new Date(),
    "email" : authctx.email
  }]
  
  var mergedArray = previousArray.concat(CurrentdeleteArray);
  var mergedJsonArray = JSON.stringify(mergedArray);
  localStorage.setItem('completedtask', mergedJsonArray);
    const userDoc = doc(db, 'Users', id);
    document.getElementById('icon'+id).style.visibility = 'visible';
    document.getElementById(id).style.transform = 'scale(1.2)';
    // document.getElementById(id).style.transform = 'scale(1.1)';
    // document.getElementById('icon'+id).style.transform = 'scale(1.1)';
    audioRefDel.current.play();
    await deleteDoc(userDoc);
    const email = localStorage.getItem('email');
    // getUsers(email, id);
    searchQuery();
    let filterById = JSON.parse(localStorage.getItem('completedtask'));
    filterById = filterById.filter(item => item.email === email);
    setCompletedTaskList(filterById);
    authctx.isDeleteHandler(true);
}
const handleMouseOver = (id) => {
 
  // console.log('icon'+id);
 document.getElementById('icon'+id).style.visibility = 'visible';
 document.getElementById(id).style.transform = 'scale(1.2)';
};

const handleMouseOut = (id) => {
 
  // console.log('icon'+id);
  if(isHovered){

  }else{
    document.getElementById('icon'+id).style.visibility = 'hidden';
    document.getElementById(id).style.transform = '';
  }
 
};

const editHander = (id, title, desc, duedate, priority, type, bool) => {
   
  // console.log(id, title, desc, duedate, priority, type);
  authctx.editTask(bool, type, id, title, desc, duedate, priority);
  audioRef.current.play();

}
 
    return(
        <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 0 }} id="customized-dialog-title">
        <SearchIcon className="searchIcon" sx={{color: '#cecece', fontSize: '30px', position: 'absolute', left: 10, top: 25 }}/><input onChange={()=> searchQuery()} id="searchvalue" className="searchInput" placeholder="Search what would you like to" autoFocus/>
        </DialogTitle>
       
        <DialogContent className="dBlock" sx={{paddingLeft: '50px'}}>
          {matchedData.length > 0 ? matchedData.map((users, index)=> {
            
          let isYesterday = '';
          const todayDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })
          let yesterday = new Date();
           yesterday.setDate(yesterday.getDate() - 1);
           yesterday = yesterday.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })
            
         
          

           return (
            <div key={index} className='cardBlock'>
         <span id={users.id} className='borderRadi' onClick={() => {deleteHandlerChange(users.id, users.title, users.Description, users.DueDate)}} onMouseOver={() => {handleMouseOver(users.id)}} onMouseOut={()=> {handleMouseOut(users.id)}}><CheckSharpIcon className='iconDefault' id={"icon"+users.id} sx={{fontSize: '16px', color: '#6a6a6a'}}/></span>
         <div className='editDiv' onClick={()=> {editHander(users.id, users.title, users.Description, users.DueDate, users.Priority,'edit', true)}}>
         <h4>{users.title} </h4>
         <p>{users.Description}</p>
         <small><HistorySharpIcon sx={{color: new Date(todayDate) <= new Date(users.DueDate) ? 'green' : 'red', marginRight: '0px', fontSize: '1.1rem', verticalAlign: 'text-bottom'}}/> {formatDate(users.DueDate)}</small>
         <BorderColorOutlinedIcon className='editIcon' sx={{fontSize: '1.5rem'}}/>
         </div>
         </div>
           )
          }): ""}
           
        </DialogContent>
        <DialogActions>
        {/* <IconButton
          aria-label="close"
          onClose={()=> handleClose(false)}
          sx={{
            position: 'absolute',
            bottom: 8,
            roght: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon /> Close
        </IconButton> */}
        </DialogActions>
        <audio ref={audioRefDel}>
         <source src="./audio/pop-up-something-160353.mp3" type="audio/mpeg" />
         Your browser does not support the audio element.
         </audio>
         <audio ref={audioRef}>
         <source src="./audio/mouse-click-153941.mp3" type="audio/mpeg" />
         Your browser does not support the audio element.
         </audio>
      </Dialog>
    )
}

export default SearchView;