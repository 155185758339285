import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../Home/Footer';
import Header from '../Header/Header';
import '../subpages/subpages.css';

const Privacy = () => {
    // const [pages, setPages] = useState();
 
//  useEffect(()=> {
//   getpageDate();
//  },[])
// const getpageDate = async() => {
//   const PageData = await axios.get(
//       "https://wundrfly.com/blog/wp-json/wp/v2/pages/3899"
//     );
//     console.log(PageData);
//     setPages(PageData.data);
// }
  return (
    <div>
    <Header></Header>
    <div className='parentContainer'>
      
    
    <div className='privacyPageMain wp-pages'>
      <h2 style={{textAlign: 'center', margin: '30px 10px', padding: '50px 0'}}>Privacy Policy</h2>
    <h3>Last modified February 1, 2024 with an effective date of February, 2024.</h3>

<h3>Contact & Data Protection Officer:</h3>
<p>
    TaskTicks (A subsidiary of Dijura Pty Ltd)<br />
    ABN: 36 625 628 186<br />
    697 Waverley Road<br />
    Glen Waverley<br />
    VIC 3150<br />
    Australia<br />
    Email: <a href="mailto:support@taskticks.com">support@taskticks.com</a>
</p>

<p>
    Our Data Protection Officer can be contacted via <a href='/contact-us'>contact us form</a> or via <a href="mailto:support@taskticks.com">support@taskticks.com</a>.<br />
    If you have unresolved concerns, you have the right to complain to a data protection authority.
</p>

<p><strong>No surprises:</strong> We’ll only ever collect, use and share your information in ways that are described in this policy.</p>
<p><strong>We'll keep your information safe:</strong> We're committed to the confidentiality and security of the personal data you give us.</p>
<p><strong>You're always in control:</strong> Update your profile and communication preferences at any time.</p>

<h3>About This Policy:</h3>
<p>
    This privacy policy ("Policy") informs you of our practices when handling your Information through the Services (defined below). In this Policy, "TaskTicks," "we," "our" or "us" refers to TaskTicks (A Subsidiary of Dijura Pty Ltd), a company registered in Australia with its registered address located at 697 Waverley Road, Glen Waverley, VIC 3150, Australia. When you are using TaskTicks in your personal capacity, we are the data controller under the applicable privacy laws, and this Policy applies.
</p>

<p>
    When you are using TaskTicks as a member of an organization that is a customer of TaskTicks (for example, when accessing a TaskTicks for teams workspace that relates to your employer if that employer is a TaskTicks customer), we are a data processor under the applicable privacy laws, and that organization is the data controller. In those circumstances, your organization's privacy policy applies, and you should refer to its privacy policy for information about its privacy practices. For clarity, we are not responsible for the privacy practices of third parties. Please be aware that this Policy does not apply to your activities or any Information that you disclose to your organization, when that organization is the data controller.
</p>

<p>
    For the purpose of this Policy, "Information" means any information relating to an identified or identifiable individual. This includes information you provide or generated when you use: (a) our apps, including TaskTicks (each an "App" and collectively the "Apps"); and (b) TaskTicks.com and any other dedicated TaskTicks websites, which link to this policy ("Website" and together with the "Apps" the "Services"). When you use the Services, you accept and understand we collect, process, use and store your Information as described in this Policy. If you do not agree with this Policy, you must not use any of the Services. If you change your mind in the future, you must stop using the Services and you may exercise your rights in relation to your Information as set out in this Policy.
</p>
<h2>1. Information we collect:</h2>

<h3>Information you provide to us:</h3>
<p>
    <strong>Registration information:</strong> For example, when you create an account on the Website, you will be asked to provide your name, job title, email, VAT ID or ABN, and a password. For paid services, including TaskTicks's Pro and Business plans, we also collect your billing address, transaction information, tax identification number or ABN, Stripe identification number, and invoice address ("Payment Information").
</p>

<p>
    <strong>Information collected via voluntary activities:</strong> For example, when you communicate with us via email, or participate in surveys, we ask you to provide your email address and any Information you choose to provide to allow us to assist you. The Information we collect from you varies depending on the survey, but we typically ask you questions about your interests and habits, your opinion about different products and services, and what you'd like to be offered in the future. We also collect your ratings, opinions, preferences, questions, pictures, and responses associated with that survey.
</p>

<p>
    <strong>Careers:</strong> If you decide to apply for a job with us, you may submit your contact information and your resume online. We will collect the Information you choose to provide us as part of your job application, such as your contact information, current employment information, and other information you choose to submit with your application and on your resume. We are using a third-party platform, Workable, to help us store and process the job applications that we receive.
</p>

<h3>Information we automatically collect or is generated about you when you use the Services:</h3>
<p>
    <strong>Identifiers:</strong> Such as your IP address, device ID, and device information (such as model, brand, and operating system).
</p>

<p>
    <strong>Geolocation information:</strong> Such as your GPS information when you use the location reminder feature of the Services. Where required, we will obtain your consent prior to collecting such Information.
</p>

<p>
    <strong>Cookies:</strong> We use cookies and other similar technologies ("Cookies") to enhance your experience when using the Services. For more information about our Cookies policy, see below How We Use Cookies and Similar Technologies section.
</p>

<p>
    <strong>Information you generate when using the Services:</strong> You may provide Information as part of your use of the Services, including any Information you provide when sending messages through the Services. Also, if you choose to share and collaborate on a task with your co-workers or friends, we will collect the email address of your co-workers or friends. Please make sure you have permission from your co-workers or friends before sharing Information referring to your co-workers or friends with us. Additionally, for the use of TaskTicks, please make sure you have all permissions and rights to upload the Information required on TaskTicks.
</p>

<p>
    <strong>Information regarding your use of the Services:</strong> Such as app use information, interactions with our team, and transaction records.
</p>

<p>
    <strong>Information received from third parties:</strong> Information we receive from third party platforms when you register through a third party account (such as Facebook or Google) or when you connect other apps to our Services (such as Slack and Dropbox), we receive Information which may include your username, email address, and profile picture.
</p>

<p>
    <strong>Information from platforms our Services relies on:</strong> Such as for transaction information and payment verification.
</p>

<h3>Children:</h3>
<p>
    Our Services are not targeted at children, and we do not knowingly collect Information from children under the age of 13. If you learn that a child has provided us with Information in violation of this Policy, please contact us as indicated below.
</p>
<h2>2. How we use your personal Information:</h2>

    <p>
        We use your Information to:
    </p>

    <ol>
        <li>
            <strong>Provide you with the Services:</strong> We will use your Information to perform our contractual obligation towards you to allow you to create an account and use the Services. The Information we process when doing so includes your registration information, Information you provide to us when using the Services, identifiers, Information you generate when using the Services, and Information regarding your use of the Services such as transaction information. We also use your Information when you activate certain features of the Services, such as your Geolocation information when you use the location reminder feature. If you are a user of TaskTicks's paid plans, we will use your Payment Information for payment processing purposes as well as sales tax collection and reporting as required by law.
        </li>

        <li>
            <strong>Improve and monitor the Services:</strong> It is in our legitimate interests to improve our Services for our customers. When doing so, we may collect Information we automatically collect or is generated about you when you use the Services, as well as non-personal Information about your device such as device manufacturer, model and operating system, and the amount of free space on your device.
        </li>

        <li>
            <strong>Provide you with support and to respond to your requests or complaints:</strong> If you reach out to us for support, we will use your Information to respond to and resolve your queries and complaints and facilitate support (e.g. retrieval of a forgotten password). When doing so, we perform our contractual obligation towards you. The Information we process when doing so includes your registration information, your identifiers, and any other information about you collected via our customer support channels.
        </li>

        <li>
            <strong>Conduct analytics:</strong> It is in our legitimate interests to analyze the use of, and any other interaction or interest in our Services. When doing so we will process Information we automatically collect or is generated about you when you use the Services to create anonymized and aggregated data regarding your App usage.
        </li>

        <li>
            <strong>Process your job application:</strong> It is in our legitimate interest to process the Information that you choose to submit when you apply for a job with us.
        </li>

        <li>
            <strong>Send you newsletters about product news, tips and tricks, daily productivity reports:</strong> We will send you emails with daily reports, newsletters with product news, and tips and tricks to use our Services. When doing so, we process your registration information. Your consent can be withdrawn at any time by following the unsubscribe mechanism at the bottom of each communication.
        </li>

        <li>
            <strong>Prevent fraud, defend TaskTicks against legal claims or disputes, enforce our terms and comply with our legal obligations:</strong> It is in our legitimate interest to protect our interests by (1) monitoring the use of the Services to detect fraud or any other user behavior which prejudices the integrity of our Services, (2) taking steps to remedy aforementioned fraud and behavior, (3) defending ourselves against legal claims or disputes, and (4) enforcing our terms and policies. When doing so, we will process the Information relevant in such a case, including Information you provide us, Information we automatically collect about you, and Information which is provided to us by third parties.
        </li>

        <li>
            <strong>Conduct surveys and Interviews:</strong> From time to time, we may ask you to participate in surveys and interviews we conduct which are in our legitimate interest because they help us understand our user base and improve the Services. If you participate, we process your registration information and any other Information collected through the survey questions.
        </li>
    </ol>
    <h2>3. How we use cookies and similar technologies:</h2>

<p>
    Cookies are small files of letters and numbers that we store on your browser or the hard drive of your computer. They contain information that is transferred to your computer's hard drive.
</p>

<p>
    Our Services use Cookies to collect information about your browsing activities and to distinguish you from other users of our Services. This aids your experience when you use our Services and also allows us to improve the functionality of our Services.
</p>

<p>
    We use the following cookies:
</p>

<ul>
    <li>
        <strong>Strictly necessary cookies:</strong> Some cookies are strictly necessary to make our Services available to you; for example, to perform your login functionality and for user authentication and security. We cannot provide you with the Services without this type of cookies.
    </li>

    <li>
        <strong>Functional cookies:</strong> These are used to recognise you when you return to our Website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
    </li>

    <li>
        <strong>Analytical and advertising cookies:</strong> We also use cookies for analytics purposes and advertising in order to operate, maintain, and improve our Services. We use third-party analytics providers, including Google Analytics, to help us understand how users engage with the Services. Google Analytics uses first-party cookies to track user interactions which helps show how users use our Services. This information is used to compile reports and to help us improve our Services. The reports disclose Website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our Website by going to <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> or via Google's Ads settings.
    </li>
</ul>

<table>
    <thead>
        <tr>
            <th>Cookie</th>
            <th>Purpose</th>
            <th>Retention</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Google Analytics</td>
            <td>Analyzing website traffic and user behavior</td>
            <td>90 days</td>
        </tr>
        <tr>
            <td>Datadog</td>
            <td>Monitoring web performance and user experience</td>
            <td>Session based</td>
        </tr>
        <tr>
            <td>Stripe</td>
            <td>Handling payments and pricing/upgrade page</td>
            <td>Session based</td>
        </tr>
        <tr>
            <td>Zendesk</td>
            <td>Loading images and providing support on Help Center</td>
            <td>Session based</td>
        </tr>
        <tr>
            <td>YouTube</td>
            <td>Displaying videos on Help Center pages</td>
            <td>Varies</td>
        </tr>
        <tr>
            <td>Cloudinary</td>
            <td>Loading and optimizing images</td>
            <td>Session based</td>
        </tr>
        <tr>
            <td>Sprig</td>
            <td>User behaviour and in-app survey</td>
            <td>Session based</td>
        </tr>
    </tbody>
</table>

<p>
    You can block cookies by setting your internet browser to block some or all cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to use our Services.
</p>

<p>
    Except for essential cookies, all cookies will expire after a maximum of 540 days.
</p>  
<h2>4. Who we share your personal Information with:</h2>

    <p>
        We share your Information with selected third parties, including:
    </p>

    <ul>
        <li>
            <strong>Other users:</strong> who will see your profile information and any other information you choose to share with them through the Services.
        </li>

        <li>
            <strong>Vendors and service providers:</strong> we rely on for the provision of the Services, for example:
            <ul>
                <li>Cloud service providers (Microsoft Azure, Amazon Web Services, OpenAI, Google Cloud)</li>
                <li>Customer support solution providers (Zendesk Inc.)</li>
                <li>Analytics providers (Google LLC)</li>
            </ul>
        </li>

        <li>
            <strong>Third Parties Service Integrations:</strong> When you connect third-party apps to the Apps, you authorize us to share designated Information and data created and/or uploaded by you to our server with these third-party services of your choice on your behalf.
        </li>

        <li>
            <strong>Communications platform providers:</strong> who help us manage and send newsletters to you in relation to the Services (SendGrid, Mailgun, MailChimp).
        </li>

        <li>
            <strong>Payment Processors:</strong> such as Stripe, responsible for processing your Information. More information is available at <a href="https://stripe.com/gb/privacy" target="_blank">https://stripe.com/gb/privacy</a> for Stripe.
        </li>

        <li>
            <strong>Law enforcement agencies, public authorities, or other judicial bodies and organizations:</strong> We disclose Information if legally required or if we have a good faith belief that such use is reasonably necessary to comply with a legal obligation, process, or request.
        </li>

        <li>
            <strong>Change of corporate ownership:</strong> If we are involved in a merger, acquisition, bankruptcy, reorganization, partnership, asset sale, or other transaction, we may disclose your Information as part of that transaction.
        </li>
    </ul>

    <p>
        Although we have security measures in place, the transmission of Information via the internet is not completely secure. We are not responsible for how third-party integration services may collect, use, or share the Information you send from the Apps. Please review the privacy policy of such third-party integration partners before connecting those services to the Apps.
    </p>

    <p>
        If you join a TaskTicks for teams workspace relating to an organization that is a customer of TaskTicks, we share Information about you with the organization and other users who belong to that organization. Your organization is the data controller of Information in its workspace; please see your organization's privacy policy for information about its privacy practices.
    </p>

    <h2>5. Where we store your Information:</h2>

    <p>
        Your Information will be processed by our employees and service providers in the USA, Australia, and India. If you choose to use our Services from the European Economic Area ("EEA"), the United Kingdom ("UK") or other regions of the world with laws governing data collection and use that may differ from USA, Australia, and Indian laws, then please note that you are transferring your Information outside of those regions to the USA, Australia, and India for storage and processing. We may transfer Information from the EEA or the UK to the USA, Australia, India, and other third countries based on European Commission-approved or UK Government-approved Standard Contractual Clauses, or otherwise in accordance with applicable data protection laws. Also, we may transfer your data from the USA, Australia, and India to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating our Services. You can contact us as detailed below if you want more information about our data transfer practices.
    </p>

    <h2>6. How long we store your Information:</h2>

    <p>
        Your Information is kept for as long as necessary to achieve the purposes set out above. We determine the retention period considering various criteria, such as the type of Services provided to you, the nature and length of our relationship with you, and mandatory retention periods provided by law. Some Information may be stored longer for legitimate interests or as required by law.
    </p>

    <h2>7. Your rights:</h2>

    <p>
        If you are based in the EEA or the UK, you have certain rights in relation to your Information:
    </p>

    <ul>
        <li>
            <strong>Access:</strong> You have the right to access Information, receive an explanation of how we use it, and who we share it with.
        </li>

        <li>
            <strong>Erasure:</strong> You have the right to delete your account and erase your Information. Note that Information held by third-party data controllers may require direct contact with them.
        </li>

        <li>
            <strong>Objection:</strong> You may have the right to object to our processing of your Information, including for direct marketing purposes.
        </li>

        <li>
            <strong>Other rights:</strong> Includes portability, correction, and restriction of processing.
        </li>
    </ul>

    <p>
        You can contact us at <a href="https://TaskTicks.com/contact" target="_blank">https://TaskTicks.com/contact</a> to exercise your rights. Please note there are exceptions and limitations to each right.
    </p>

    <h2>8. Contact & complaints:</h2>

    <p>
        For questions, comments, and requests regarding this Policy, please contact us at <a href="https://TaskTicks.com/contact" target="_blank">https://TaskTicks.com/contact</a>. If you wish to make a complaint about how we process your Information, you can also send an email to <a href="mailto:support@TaskTicks.com">support@TaskTicks.com</a>.
    </p>

    <h2>9. Changes:</h2>

    <p>
        If we make any material changes to this Policy, we will post the updated Policy here and notify our users through the Services and/or newsletters. Please check this page frequently for updates or changes.
    </p>

    <h2>Who Are We And How Can You Contact Us?</h2>

    <p>
        TaskTicks is provided by TaskTicks (A subsidiary of Dijura Pty Ltd), a private company registered in Australia. TaskTicks is the Data Controller of any information collected from you by us and as mentioned in this privacy policy. Our details are as follows:
    </p>
    <p>
    TaskTicks (A subsidiary of Dijura Pty Ltd)<br />
    ABN: 36 625 628 186<br />
    697 Waverley Road<br />
    Glen Waverley<br />
    VIC 3150<br />
    Australia<br />
    Email: <a href="mailto:support@taskticks.com">support@taskticks.com</a>
   </p>
   {/* {
      pages ? <div>
       <p
              dangerouslySetInnerHTML={{
                __html: pages.content.rendered.replace(/&#8217;/g, "'").replace(/&#8220;/g, '"').replace(/&amp;/g, "&").replace(/&#8211;/g, "–"),
              }}
            ></p>
      </div> : ''
   } */}
   </div>
   </div>
    <Footer />
    </div>
  )
}

export default Privacy
