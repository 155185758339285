import * as React from 'react';
import Header from './Header'
// import { DataGrid } from "@mui/x-data-grid";
// import { dbSearch  } from '../../auth/config';
// import { collection, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
 
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
 
import Typography from '@mui/material/Typography';
 
import { Button } from '@mui/material';

import AuthContext from '../../auth/Auth-context';
 
import SideButton from './SideButton';

const drawerWidth = 270;
// const columns = [
//     // { field: ' ', headerName: 'ID', width: 300 },
//     { field: 'terms', headerName: 'Search Terms', width: 300 },
//     { field: 'Date', headerName: 'Date', width: 300 },
//     { field: 'Time', headerName: 'Time', width: 300 }
//   ];

   

const Admin = (props) => {
 const authCtx = useContext(AuthContext);
 
 
 
  
  
  
  
   
 
 
   
    return (
        <div><Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}

        >
          <Toolbar style={{background: '#fff'}}>
            <Typography  style={{color: '#333', fontWeight: 'bold'}} variant="h6" noWrap component="div">
              Analytics Tool 
            </Typography>
            <Header />
          </Toolbar>
        </AppBar>
        {
          authCtx.email ? <SideButton/> : null
        }
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
            <div className='container pt-1 text-center'>
              <h1><b>Welcome to Analytics Tool</b></h1>
              <code>Select the left side button to navigate respective data</code>
              
               
             
            </div>
          
        </Box>
      </Box>
            
            
        </div>
    );
}

export default Admin;