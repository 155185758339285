import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Home/Footer';
import '../subpages/subpages.css';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const About = () => {
  const [addclass, setaddClass] = useState(false);
  const [buttonStates, setButtonStates] = useState({})
  
  useEffect(()=> {
   // scrollFunction();
    // Function to handle scroll
    const handleScroll = () => {
      // Your logic for handling scroll
      console.log('Scrolled!');
      console.log(document.documentElement.scrollTop)
      if( document.documentElement.scrollTop >= '850' && document.documentElement.scrollTop < '3814'){
        setaddClass(true);
   
      } else if(document.documentElement.scrollTop <= '850' || document.documentElement.scrollTop > '3815'){
        setaddClass(false);
        
      } 
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

 
  })
 
  
 
  const clickHandler = (id) => {
    console.log(id);
    setButtonStates(id);
    // setButtonStates((prevStates) => ({
    //   // ...prevStates,
    //   [id]: !prevStates[id] || false,
    // }));
  };
  console.log(buttonStates);
  return (
    <div>
      <Header></Header>
      <div className='abtContainer'>
      <div className='HeadlineABt'>
      <h1>Welcome to TaskTicks - Your All-in-One Task Manager and To-Do List App</h1>
      </div>
      <div className='BannerAbt'>
      <img src='../images/aboutusbanner.PNG'></img>
      </div>
      <div className='descriotionAbts'>
        <h3>At TaskTicks, our mission is to simplify the way you manage your daily tasks. We believe that a well-organized day leads to a productive and stress-free life.</h3>
      </div>
      <div className='slideContainer'>
      <div className= 'leftDiv'>
      <ul className={addclass ? 'fixedClass' : ''}>
      <li onClick={(e)=> clickHandler('btnide')} id='btnide' className={buttonStates === 'btnide' ? 'bdLeft' : ''}><a href='#ide'> Our Vision</a></li>
      <li onClick={(e)=> clickHandler('btnwht')} id='btnwht' className={buttonStates === 'btnwht' ? 'bdLeft' : ''}><a href='#wht'>  What Sets Us Apart</a></li>
      <li onClick={()=> clickHandler('btncore')} id='btncore' className={buttonStates === 'btncore' ? 'bdLeft' : ''}><a href='#core'>Our Philosophy</a></li>
      <li onClick={()=> clickHandler('btnhww')} id='btnhww' className={buttonStates === 'btnhww' ? 'bdLeft' : ''}><a href='#hww'> Join Our Movement</a></li>
      <li style={{display: 'none'}} onClick={()=> clickHandler('btnoHis')} id='btnoHis' className={buttonStates === 'btnoHis' ? 'bdLeft' : ''}><a href='#oHis'> Our history</a></li>
      </ul>
      </div>
      <div className='rightDiv'>
      <div id='ide'>
      <h1 style={{paddingTop: '20px', margin: 0}}> Our Vision </h1>
      <p style={{fontSize: '1.125'}}>We started TaskTicks with a clear vision: to create a task management tool that balances simplicity with powerful functionality. In the bustling pace of modern life, we understand the need for a system that easily adapts to your individual lifestyle.</p>
  
       
      </div>

      <div id='wht'>
      <h1 style={{paddingTop: '20px', margin: 0}}> What Sets Us Apart </h1>
      <p style={{fontSize: '1.125'}}>TaskTicks stands out not just as a task management tool, but as a complete system designed for modern needs. Our unique features include:</p>
      <p style={{fontSize: '1.125'}}> <b></b></p>
      <p style={{fontSize: '1.125'}}></p>
      <div className='inlineDisplayParent'>
      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id01.PNG' />
      <h3>User-Friendly Task Management</h3>
      <article>Our intuitive interface makes managing your tasks effortless and quick.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id02.PNG' />
      <h3>Intelligent Scheduling</h3>
      <article>Leveraging advanced technologies, we suggest optimal times for your tasks, aiding in better time management.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id03.PNG' />
      <h3>Effortless Collaboration</h3>
      <article>Share tasks, assign responsibilities, and track team progress with ease.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id04.PNG' />
      <h3>Personalized Alerts</h3>
      <article>Customizable reminders ensure you're always on top of your deadlines.</article>
      </div>
      </div>


      </div>
      </div>
      
      <div id='core'> 
      <h1 style={{paddingTop: '20px', margin: 0}}> Our Philosophy </h1>
      <p style={{fontSize: '1.125'}}> Our core belief is that productivity tools should be empowering, not burdensome. TaskTicks is crafted to be flexible and intuitive, catering to individuals, professionals, teams, students, and anyone seeking to enhance their daily efficiency.</p>

      <div className='inlineDisplayParent' style={{display: 'none'}}>
      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id01.PNG' />
      <h3> Working without borders </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id02.PNG' />
      <h3> Balancing ambition and wellbeing</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id03.PNG' />
      <h3> Supporting our users’ goals</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id04.PNG' />
      <h3> Thinking in decades, not in quarters </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>


      </div>
      </div>
     
      <div id='hww' style={{paddingBottom: '30px'}}> 
      <h1 style={{paddingTop: '20px', margin: 0}}> Join Our Movement </h1>
      <p style={{fontSize: '1.125'}}>Become a part of the TaskTicks community and take your productivity to the next level. Sign up and start experiencing a more organized life today.</p>
      <p style={{fontSize: '1.125'}}>Ready to help you check off each task!</p>
      <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link>
      <div className='inlineDisplayParent' style={{ display: 'none'}}>
      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id01.PNG' />
      <h3> Working without borders </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id02.PNG' />
      <h3> Balancing ambition and wellbeing</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id03.PNG' />
      <h3> Supporting our users’ goals</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id04.PNG' />
      <h3> Thinking in decades, not in quarters </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>


      </div>
      </div>

      <div id='oHis' style={{display: 'none'}}>
      <h1 style={{paddingTop: '20px', margin: 0}}> Our history </h1>
      <p style={{fontSize: '1.125'}}> We’re committed to building a company and culture with a lasting impact on the world.</p>

      <div className='inlineDisplayParent' style={{paddingBottom: '100px'}}>
      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id01.PNG' />
      <h3> Working without borders </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id02.PNG' />
      <h3> Balancing ambition and wellbeing</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id03.PNG' />
      <h3> Supporting our users’ goals</h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>

      <div className='inlineChild'>
      <div className='sizeTile'>
      <img src='../images/id04.PNG' alt='Thinking in decades, not in quarters'/>
      <h3> Thinking in decades, not in quarters </h3>
      <article> From Jamaica to Poland, Taiwan to Australia, diversity is in Doist’s DNA. We believe the future of work transcends all kinds of borders.</article>
      </div>
      </div>


      </div>
      </div>
     
      </div>
      </div>
      </div>
      
      <Footer></Footer>
    </div>
  )
}

export default About;
