import React, { useContext, useState, useEffect } from 'react';
import { Button, Container } from '@mui/material';
import "./Profile.css";
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../auth/Auth-context';
import { db } from "../auth/config";
import { collection, addDoc, updateDoc, getDocs, deleteDoc, doc, query, where, orderBy} from "firebase/firestore";

const Myaccount = () => {
  const usercollRef = collection(db, "profile");
  const [userName, setUserName] = useState();
  const [emailID, setEmailID] = useState();
  const [userID, setUserID] = useState();


  const authctx = useContext(AuthContext);
  const history = useNavigate();


  const logoutHandler = (status) => {
    console.log(status)
    authctx.logout();
    localStorage.setItem('email', "");
    localStorage.setItem('idtoken', "");
    history('/auth/password');
  }
  
  useEffect(()=> {
    setEmailID(authctx.email);
    setUserName(authctx.username);
    getProfileDetails(authctx.email);
  },[])
 
  const getProfileDetails = async(emailIdAcc) => {
    const q = query(usercollRef, where('Email', '==', emailIdAcc));
    let data = await getDocs(q);
    console.log(data);
    data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
    console.log(data);
    if(data.length > 0){
      data.map((value, index)=> {
        setUserName(value.title);
        setUserID(value.id)
      })
     }
  }

  const updateprofileinfo = async()=> {
    let nameAcc = document.getElementById('nameAcc').value;
    const userDoc = doc(usercollRef, userID)
    console.log(userDoc);
    await updateDoc(userDoc, {
 
      title: nameAcc,
      
    })
    alert("updated")
  }
  const saveProfile = async() => {
    let emailIdAcc = document.getElementById('emailIdAcc').value;
    let nameAcc = document.getElementById('nameAcc').value;
    console.log(emailIdAcc, nameAcc);

    await addDoc(usercollRef, {
      Email: emailIdAcc,
      title: nameAcc    
    });
    alert('changes are saved');

    

  }
  return (
    <Container maxWidth="md" >
        <h1 style={{fontSize: '24px'}}>My Profile</h1>
        <hr style={{marginBottom: '20px'}}/>
         <div className='myaccountContainer' maxWidth="md" >
         <div className='formInput'>
         <label>Email</label>
         <input type='email' id='emailIdAcc' value={emailID} disabled/>
         </div>
         <div className='formInput'>
         <label>Name</label>
         <input type='text' id='nameAcc' placeholder={userName}/>
         </div>
         <div className='formInput'>
         <label>Password</label>
         <input type='password' value="HiddenPassword" disabled/>
         </div>
         <div className='' style={{marginTop: '10px'}}>
         <small onClick={()=> logoutHandler(true)}><Link style={{fontWeight: '900', textDecoration: 'none'}} to='/auth/password'>Reset password</Link></small>
      </div>
         <div style={{textAlign: 'right'}}>
         <Button variant="contained" onClick={() => userID ? updateprofileinfo() :  saveProfile()} sx={{textTransform: "inherit", width: '200px', background: '#2791f7'}}>Save Changes</Button>
         </div>
         </div>

        
   </Container>
  )
}

export default Myaccount
