import React from 'react';
import SideButton from './SideButton';
import Header from './Header'
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import Yrr_mynewtab_data from './Yrr_mynewtab_data';


function Yrr_mynewtab(props) {
  const authCtx = useContext(AuthContext);

 
  
 
  // console.log(filterTerms)
  return (
      <div> 
     <Header/>
    <SideButton />
    {authCtx.email ? <Yrr_mynewtab_data/> : ''}   
      </div>
  );
}






export default Yrr_mynewtab;