import React from "react";
import SideButton from "./SideButton";
import { DataGrid } from "@mui/x-data-grid";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import {dbSearch} from "../../auth/config";
import { db } from "../../auth/config";
import styled from 'styled-components';
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, Checkbox } from "@mui/material";
import AuthContext from "../../auth/Auth-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, blueGrey } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";

const styles = {
    background: 'white',
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center', // Uncomment if needed
    // boxShadow: '0px 1px 9px 0px rgb(0 0 0 / 14%)', // Uncomment if needed
    zIndex: 9999,
    position: 'fixed',
    right: '10px',
    top: '10px',
    color: 'black',
    textTransform: 'none',
    fontWeight: 800,
  };


const Header = () => {
    const history = useNavigate();
    const authctx = useContext(AuthContext)
    const [headerFix, setHeaderFix] = useState(false);
    const isUserloggedIn = authctx.isLoggedIn;
    const [MenuOpen, setMenuopen] = useState(false);
    const location = useLocation();
    const [searchValue, setSearchValue] = useState();
    const searchCollectionRef = collection(dbSearch, "taskticks");
    const inviteUserCollection = collection(db, "inviteUsers");
    let emailId;
    useEffect(() =>{
     emailId = localStorage.getItem('email');
     let idToken = localStorage.getItem('idtoken');
     let username = localStorage.getItem('username');
     console.log(idToken);
     idToken = idToken ? idToken : null;
     authctx.login(idToken, emailId, username);
     if(idToken) {
       getUserData(idToken);
     }
    //  console.log(authctx);
    },[])
    
    const postSearch = async(value, date) => {
     console.log(value, date);
   
     await addDoc(searchCollectionRef, {
      terms:  value,
      Date: date
     });
   }
   
    const menuOpenHandler = (res)=> {
     setMenuopen(res)
   }
   
   const menuCloseHandler = (res)=> {
     setMenuopen(res)
   }
   
   
   useEffect(()=> {
     const pathname = location.pathname;
   
   // Now you can use the pathname variable as needed
   console.log('Current pathname:', pathname);
   }, [])
   
   
 
   
   
   
    
    const logoutHandler = (status) => {
      console.log(status)
      authctx.logout();
      localStorage.setItem('email', "");
      localStorage.setItem('idtoken', "");
      history('/auth/login');
    }
   
   
    const getUserData = (token) => {
     fetch('https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w', {
       method: 'POST',
       body: JSON.stringify({
         idToken : token
       }),
       headers: {
         'content-Type': 'application/json'
       }
     }).then(async (res) => {
       console.log(res);
       if(res.ok){
         return res.json();
         
       } else {
        const data_1 = await res.json();
           console.log(data_1);
           console.log(data_1.error.message);
           authctx.login(null);
       }
     }).then(async(data) => {
       console.log(data);  
       if(data.users) {
        //  history('/admin');
         // alert('login successfully')
       const dataInvite = await getDocs(inviteUserCollection);
       console.log(dataInvite.docs);
       let dataArray = dataInvite.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
   
         console.log(dataArray);
         console.log(data.users);
       data.users.map((value, index)=> {
         dataArray.map((value2, index2)=> {
           if(value.email === localStorage.getItem('email')) {
             // authctx.email = value.email;
             // authctx.username = value.displayName;
           
             // console.log(authctx, value2, value)
             
           }
         })
           
   
       })
       var getUserIDarray = dataArray.find(item => item.EmailId === localStorage.getItem('email'));
       console.log(getUserIDarray);
       authctx.access({
        Level: getUserIDarray.Level,
        SitePermission: getUserIDarray.SitePermission
      });
         // history('/dashboard');
       
         // console.log(authctx.email);
         //   authctx.email = data.users[0].email;
         //   authctx.username = data.users[0].displayName;
         // history('/dashboard');
       }
       
     }).catch(err => {
   
       alert('Session Expired, Please Login Again');
       authctx.login(null);
       history('/auth/login');
     });
    }
    
     useEffect(()=> {
       scrollFunctionHeader();
       window.onscroll = function() {scrollFunctionHeader()};
     })
     function scrollFunctionHeader(){
       const pathname = location.pathname;
       if(document.documentElement.scrollTop >= '100'){
         if(pathname === '/about-us'){
           setHeaderFix(false) 
         } else {
           setHeaderFix(true) 
         }
         
       } else{
         setHeaderFix(false) 
       }
     }
   
     
     
    //  console.log(authctx)
   
     return (

       <div>
         <header>
          <div style={styles}> 
          {/* <div className='logoImage'>
          <span className='logoTxt'><Link to='/'><img src='../images/Logo/logo.png' alt='TaskTicks'/></Link></span> 
          </div> */}
          <span className='menuList'>
          <ul>
          <li>
          
          {/* <input onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' />  */}
          </li>
          {isUserloggedIn ? <li onClick={()=> logoutHandler(true)} style={{cursor: 'pointer'}}>
           Logout
           </li> : <span><li>
           <Link to='/auth/login'>Login</Link>
           </li>
          <li>
          
          <Link to='/auth/signup'><Button className='headerButton' variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></Link>
          </li></span>}
          </ul>
          </span>
          
          <div className='menuBtns'>
           { MenuOpen ? <span onClick={()=> menuCloseHandler(false)}><CloseIcon sx={{fontSize: '30px'}}/></span> : <span onClick={()=> menuOpenHandler(true)}><MenuIcon sx={{fontSize: '30px'}}/></span>}
          
          </div>
           </div>
         </header>
         { MenuOpen ? <span className='menuListMobile'>
          <ul>
          <li>
          
          {/* <input onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' />  */}
          </li>
          <li>
          {/* <Link to='/dashboard'>Dashboard</Link> */}
          </li>
          <li>
          {/* <Link to='/feature'>Features</Link> */}
          </li>
          {isUserloggedIn ? <li onClick={()=> logoutHandler(true)} style={{cursor: 'pointer'}}>
           Logout
           </li> : <span><li>
           <Link to='/auth/login'>Login</Link>
           </li>
          <li>
          
          <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link>
          </li></span>}
          </ul>
          </span> : ''}
          <div className='searchBox text-center mt-2 p-2'>
             {/* <input style={{width: '100%'}} onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' /> */}
           </div>
       </div>
     )
   }
   
   export default Header
   