import React from 'react';
import SideButton from './SideButton';
import Header from './Header'
import { DataGrid } from "@mui/x-data-grid";
import { db } from '../../auth/config';
import { collection, addDoc, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Checkbox } from '@mui/material';
import AuthContext from '../../auth/Auth-context';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const drawerWidth = 270;
 
const style = {
    p: 0,
    width: '100%',
    maxWidth: 768,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  };

  
function User_Management(props) {
  const [open, setOpen] = useState(false);
    const inviteUserCollection = collection(db, "inviteUsers");
    const [permissionValue, setPermissionValue] = useState();
    const [onloadData, setOnloadData] = useState([]);
    let selectedTabs = [];
    
   
    const authCtx = useContext(AuthContext);
    console.log(authCtx)
      useEffect(()=> {
        // if(authCtx){
        //   if(authCtx.email != 'bellaryharsha4u@gmail.com'){
        //     alert("Access Denied");
        //    return false;
        //   } else {
         
        //   }
        // }
        authCtx.email = authCtx.email ? authCtx.email : null;
        console.log(authCtx.email)
        // getData();
        getOnloadList();
      }, [])
      
     
     
    // You can perform further actions based on the selected rows
 
  const permissionLevel = (value) => {
    //  alert(value)
     setPermissionValue(value);
  }
  const getOnloadList = async()=> {
    const data = await getDocs(inviteUserCollection);
    console.log(data.docs)
    let dataArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));

    console.log(dataArray);
    setOnloadData(dataArray)
  }
   

  const DelUser = async(id, emailid) => {
   // alert(emailid)
   setOpen(true);
   
    let text = `Are you sure want to Delete ${emailid}`;
  if (window.confirm(text) == true) {
    text = "You pressed OK!";
    //return false
    const userDoc = doc(db, 'inviteUsers', id);
    await deleteDoc(userDoc);
    getOnloadList();
    setOpen(false);
  } else {
    text = "You canceled!";
    setOpen(false);
  }
     

  //  setOpen(false);
         
  }
   
  
    return (
        <div>
           <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
         
      >
        <CircularProgress color="inherit" />
      </Backdrop>
          
          <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}

        >
          <Toolbar style={{background: '#fff'}}>
            <Typography  style={{color: '#333', fontWeight: 'bold'}} variant="h6" noWrap component="div">
              Analytics Tool 
            </Typography>
            <Header />
          </Toolbar>
        </AppBar>
        <SideButton/>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
            <div className='container pt-1'>
            <h4><b>Active Users </b></h4>
           
            <List sx={style} aria-label="mailbox folders" >
            {onloadData ? onloadData.map((item, index) => {
                    return (
                        <div>
                        <ListItem>
                        {item.EmailId} <code style={{marginLeft: '20px'}}> {item.Level} </code>
                         <Button  color="error" sx={{position: 'absolute', right: '5px'}} onClick={()=> DelUser(item.id, item.EmailId)}>Delete</Button>
                        </ListItem>
                        <Divider component="li" />
                        </div>
                    )
                }) : ''}
       
    </List>
                
               
           
             
          </div>
        </Box>
      </Box>
            
              
        </div>
    );
}

export default User_Management;