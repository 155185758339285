import React, { useContext, useEffect, useState } from 'react';
  import Header from './Header/Header';
// import Header from '../Header/Header';
import Main from './Main';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../auth/Auth-context';
import Footer from '../dashboard/Footer';

const Dashboard = () => {
    const history = useNavigate();
    const authCtx = useContext(AuthContext);
    const [accountData, myAccountData] = useState('');

    const isLoggedIn = authCtx.token;
    // console.log(authCtx);
    useEffect(() => {
        let idToken = localStorage.getItem('idtoken');
        // console.log(idToken);
        authCtx.login(idToken);
        
        if(idToken){
            
        }else {
          
            history('/auth/login');
        }
    },[isLoggedIn])
    const Myaccount = (response)=> {
      myAccountData(response);
      console.log(response);
    }
    const returnPassMyAccRes = (response) => {
      myAccountData(response);
    }
  return (
    <div>
      <Header saveMyaccount = {Myaccount}/>
      <Main myAccount = {accountData} returnPassMyAcc = {returnPassMyAccRes}/>
      <Footer></Footer>
    </div>
  )
}

export default Dashboard
