import React, { useContext }  from 'react'
import { Calendar, momentLocalizer} from 'react-big-calendar'
import './CalendarView.css'
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'; 
import AddTask from './AddTask';
import { useEffect, useState, useCallback  } from 'react';
import { db } from '../auth/config';
import {collection, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';
import AuthContext from '../auth/Auth-context';


const localizer = momentLocalizer(moment) // or globalizeLocalizer
const CalendarView = (props) => {
    const authCtx = useContext(AuthContext);
    const [screenheight, setscreenheight] = useState();
    const [eventDetailsPopup, seteventDetailsPopup] = useState()
    const event = props.eventData;

   
    
    console.log(event)
    var updatedArray = event.map(item => {
        return {
            ...item, // Copy all existing properties
            start: new Date(item.start),
            end: new Date(item.end) // Set 'start' to a Date object
        };
    });
    console.log(updatedArray)
    const handleSelectEvent = useCallback(
        
        (event) => seteventDetailsPopup({
            'title': event.title,
            "id": event.id,
            "desc": event.Description,
            "priority": event.Priority,
            "duedate": event.DueDate    
        }),[])

      useEffect(()=> {
        setscreenheight(window.innerHeight - 250 +'px')
    }, [screenheight])
    useEffect(()=> {
      if(eventDetailsPopup){
        authCtx.editTask(true, 'edit', eventDetailsPopup.id, eventDetailsPopup.title, eventDetailsPopup.desc, eventDetailsPopup.duedate, eventDetailsPopup.priority);
      }
    }, [eventDetailsPopup]);
  console.log(screenheight)
  return (
    <div className="myCustomHeight" style={{height: screenheight}}>
    <Calendar
      events={updatedArray}
      startAccessor="start"
      endAccessor="end"
      onSelectEvent={handleSelectEvent}
      localizer={localizer}   
    />
  </div>
  )
}

export default CalendarView
