import React from 'react';
import SideButton from './SideButton';
import Header from './Header'
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import Mynewtab_data from './Mynewtab_data';



function Mynewtab(props) { 

 
  const authCtx = useContext(AuthContext);
   
  // console.log(filterTerms)
  return (
      <div> 
           <Header/>
      <SideButton />
      {authCtx.email ? <Mynewtab_data/> : ''} 
      </div>
  );
}

export default Mynewtab;