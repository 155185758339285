import React from 'react';
import { useEffect, useState, useContext } from 'react';
import Header from '../Header/Header'
import TextField from '@mui/material/TextField'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import Login from './Login';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './auth.css';
import AuthContext from './Auth-context';
import { sendPasswordResetEmail } from 'firebase/auth';
import {auth, provider} from './config';
import Footer from '../Home/Footer';




const ForgotPassword = () => {
    const history = useNavigate();
    const [screenheight, setscreenheight] = useState();
    const authCtx = useContext(AuthContext);
    const [emailApproved, setEmailApproved] = useState(false);
    
useEffect(() => {
      setscreenheight(window.innerHeight - 150 +'px')
       console.log(window.innerHeight);
    }, [])

const passwordResetHandler = (status) => {
    let userName = document.getElementById('filled-basic').value;
    if(status === true){
        sendPasswordResetEmail(auth, userName).then(data => {
            setEmailApproved(true);
            setTimeout(() => {
                history("/auth/login");
            }, 6000);
        }).catch(err=>{
            setEmailApproved(false);
            alert(err.message);
        }) 
    }
    
   
}
   
    return (
      <div>
        <Header/>
        <CssBaseline />
        {screenheight ?   <div className='signupcontainer' style={{height: screenheight}}>
        <Container maxWidth="md">
        <Grid container spacing={2}>
        <Grid xs={12} md={6}>
        <h2>Forgot your password?</h2>
        <p><small>To reset your password, please enter the email address of your taskticks account.</small></p>
        <div className='InputField'>
        <TextField className='fieldCS'  id="filled-basic" label="Email" variant="filled" />
        </div>
        
      <Button variant="contained" onClick={()=> passwordResetHandler(true)}  style={{textTransform: "inherit", width: '100%', background: '#770A97'}}>Reset my password</Button>  
       {emailApproved && <h4>Please check your <a href='https://mail.google.com/mail/u/0/#inbox' target='_blank'>gmail to reset your password</a></h4>} 
        </Grid>
        <Grid xs={12} md={6}>
        <img className='imageWidth' src='https://todoist.b-cdn.net/assets/images/9ba6f31fc750cc2b5eaf8b4fbeec2788.png' />
        </Grid>
        </Grid>
        </Container>
        </div> : ''}
        <Footer/>
        
      </div>
    )
  }
 

export default ForgotPassword
