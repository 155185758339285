import React from 'react';
import SideButton from './SideButton';
import Header from './Header'

import { useContext } from 'react';

import AuthContext from '../../auth/Auth-context';

import Searcholl_data from './Searcholl_data';

function Searcholl(props) {
const authCtx = useContext(AuthContext);


  // console.log(filterTerms)
  return (
      <div>
    <Header/>
    <SideButton />
    {authCtx.email ? <Searcholl_data/> : ''}
    
    
    
          
          
      </div>
  );
}



export default Searcholl;