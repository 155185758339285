import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import './Header/Header.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { db } from '../../src/auth/config';
import {collection, getDocs, deleteDoc, doc, query, where, orderBy} from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import GradingIcon from '@mui/icons-material/Grading';
import { useContext } from 'react';
import AuthContext from '../../src/auth/Auth-context';
import {Link, useNavigate } from "react-router-dom";
import SearchView from '../dashboard/SearchView';
import Myaccount from '../../src/profile/Myaccount';
 




const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#333'
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
  
    color: '#000000',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));
  
const SlidebarHeader = (props) => {

    const usercollRef = collection(db, 'Users'); 
    const usercollRefProfile = collection(db, 'profile');
    const history = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [emailId, setEmailId] = useState('');
    const [userName, setUserName] = useState('');
    const [userImg, setUserImg] = useState('');
    const [iscompleted, setIscompleted] = useState('');
    const [isPending, setIspending] = useState('');
    const [totaltask, setTotaltask] = useState('');
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const authctx = useContext(AuthContext)
    const [completedTaskList, setCompletedTaskList] = useState([]);
    const [searchViewOpen, setsearchViewOpen] = useState(false);
    const audioRef = useRef(null);
   



    useEffect(() =>{
  
      let idToken = localStorage.getItem('idtoken');
      let EmailId = localStorage.getItem('email');
       console.log(EmailId);
      idToken = idToken ? idToken : null;
      
      getUserData(idToken);
      getFromProfile(EmailId);
     },[])
     const getFromProfile = async(emailid) => {
      console.log(emailid);
      const q = query(usercollRefProfile, where('Email', '==', emailid));
      let data = await getDocs(q);
      console.log(data);
      data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
      console.log(data);
      if(data.length > 0){
       data.map((value, index)=> {
        setUserName(value.title);
       })
      }
      
    }
    const getUserData = (token) => {
       console.log(token);
      fetch('https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w', {
        method: 'POST',
        body: JSON.stringify({
          idToken : token
        }),
        headers: {
          'content-Type': 'application/json'
        }
      }).then(async (res) => {
          // console.log(res);
        if(res.ok){
          return res.json();
          
        } else {
         const data = await res.json();
          console.log(data);
          console.log(data.error.message);
          history('/auth/login');

        }
      }).then((data) => {
         console.log(data);  

        if(data.users) {
        //  console.log(data.users[0].email);
         data.users[0].photoUrl = data.users[0].photoUrl ? setUserImg(data.users[0].photoUrl) : setUserImg('../images/dummyProfilePic.png')
         setEmailId(data.users[0].email);
       
         
          
          data.users[0].displayName = data.users[0].displayName ? setUserName(data.users[0].displayName) : setUserName(null)
         
           console.log(data.users[0].displayName);

         let displayName = data.users[0].providerUserInfo[0].displayName ? data.users[0].providerUserInfo[0].displayName : "";
         authctx.login(token, data.users[0].email, displayName);
        //  console.log(token, data.users[0].email, displayName);
        }
        
      }).catch(err => {
        // alert(err.message);
      });
     }
  
    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
     
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
       
    };
  
   

    useEffect(()=> {
      const email = localStorage.getItem('email');
          
      authctx.isDeleteHandler(false);
    }, [authctx, searchViewOpen])


   


    // const isUserloggedIn = authctx.isLoggedIn;
    
    const logoutHandler = (status) => {
      console.log(status)
      authctx.logout();
      localStorage.setItem('email', "");
      localStorage.setItem('idtoken', "");
      history('/auth/login');
    }
    
  

   
    const Myaccount = (res) => {
      props.saveMyaccount(res);
    }
 
    
    
    


    
  
   
        


  console.log(userName)
// alert(userName);
    return (
         <div>
         
            
              
        
            {userImg ? <img onClick={handleProfileMenuOpen} style={{borderRadius: '50%', width: '30px', height: '30px', objectFit: 'cover', position: 'absolute', left: '0'}} src={userImg} alt='profile'/> :  <AccountCircle  style={{color:"#444"}}/>}
            {userName && <p>{userName}</p>}
    </div>
    );
};

export default SlidebarHeader;